/**
 * DO NOT MANUALLY EDIT THIS FILE
 * This file is generated automatically by the build script on prebuild step.
 * Please refer to documentation for more information
 */
export const messages = {
  'locales.en-GB': 'English',
  'locales.de-DE': 'Deutsch',
  'locales.fr-FR': 'Français',
  'stores.uk': 'UK (GBP)',
  'stores.eu': 'EU (EUR)',
  'stores.us': 'US (USD)',
  'countries.A1': 'Anonymous Proxy',
  'countries.A2': 'Satellite Provider',
  'countries.AD': 'Andorra',
  'countries.AE': 'United Arab Emirates',
  'countries.AF': 'Afghanistan',
  'countries.AG': 'Antigua and Barbuda',
  'countries.AI': 'Anguilla',
  'countries.AL': 'Albania',
  'countries.AM': 'Armenia',
  'countries.AN': 'Netherlands Antilles',
  'countries.AO': 'Angola',
  'countries.AP': 'Asia/Pacific Region',
  'countries.AQ': 'Antarctica',
  'countries.AR': 'Argentina',
  'countries.AS': 'American Samoa',
  'countries.AT': 'Austria',
  'countries.AU': 'Australia',
  'countries.AW': 'Aruba',
  'countries.AX': 'Aland Islands',
  'countries.AZ': 'Azerbaijan',
  'countries.BA': 'Bosnia and Herzegovina',
  'countries.BB': 'Barbados',
  'countries.BD': 'Bangladesh',
  'countries.BE': 'Belgium',
  'countries.BF': 'Burkina Faso',
  'countries.BG': 'Bulgaria',
  'countries.BH': 'Bahrain',
  'countries.BI': 'Burundi',
  'countries.BJ': 'Benin',
  'countries.BM': 'Bermuda',
  'countries.BN': 'Brunei Darussalam',
  'countries.BO': 'Bolivia',
  'countries.BR': 'Brazil',
  'countries.BS': 'Bahamas',
  'countries.BT': 'Bhutan',
  'countries.BV': 'Bouvet Island',
  'countries.BW': 'Botswana',
  'countries.BY': 'Belarus',
  'countries.BZ': 'Belize',
  'countries.CA': 'Canada',
  'countries.CC': 'Cocos (Keeling) Islands',
  'countries.CD': 'Congo',
  'countries.CF': 'Central African Republic',
  'countries.CG': 'Congo',
  'countries.CH': 'Switzerland',
  'countries.CI': "Cote d'Ivoire",
  'countries.CK': 'Cook Islands',
  'countries.CL': 'Chile',
  'countries.CM': 'Cameroon',
  'countries.CN': 'China',
  'countries.CO': 'Colombia',
  'countries.CR': 'Costa Rica',
  'countries.CU': 'Cuba',
  'countries.CV': 'Cape Verde',
  'countries.CX': 'Christmas Island',
  'countries.CY': 'Cyprus',
  'countries.CZ': 'Czech Republic',
  'countries.DE': 'Germany',
  'countries.DJ': 'Djibouti',
  'countries.DK': 'Denmark',
  'countries.DM': 'Dominica',
  'countries.DO': 'Dominican Republic',
  'countries.DZ': 'Algeria',
  'countries.EC': 'Ecuador',
  'countries.EE': 'Estonia',
  'countries.EG': 'Egypt',
  'countries.EH': 'Western Sahara',
  'countries.ER': 'Eritrea',
  'countries.ES': 'Spain',
  'countries.ET': 'Ethiopia',
  'countries.EU': 'Europe',
  'countries.FI': 'Finland',
  'countries.FJ': 'Fiji',
  'countries.FK': 'Falkland Islands (Malvinas)',
  'countries.FM': 'Micronesia',
  'countries.FO': 'Faroe Islands',
  'countries.FR': 'France',
  'countries.GA': 'Gabon',
  'countries.GB': 'United Kingdom',
  'countries.GD': 'Grenada',
  'countries.GE': 'Georgia',
  'countries.GF': 'French Guiana',
  'countries.GG': 'Guernsey',
  'countries.GH': 'Ghana',
  'countries.GI': 'Gibraltar',
  'countries.GL': 'Greenland',
  'countries.GM': 'Gambia',
  'countries.GN': 'Guinea',
  'countries.GP': 'Guadeloupe',
  'countries.GQ': 'Equatorial Guinea',
  'countries.GR': 'Greece',
  'countries.GS': 'South Georgia and the South Sandwich Islands',
  'countries.GT': 'Guatemala',
  'countries.GU': 'Guam',
  'countries.GW': 'Guinea-Bissau',
  'countries.GY': 'Guyana',
  'countries.HK': 'Hong Kong',
  'countries.HM': 'Heard Island and McDonald Islands',
  'countries.HN': 'Honduras',
  'countries.HR': 'Croatia',
  'countries.HT': 'Haiti',
  'countries.HU': 'Hungary',
  'countries.ID': 'Indonesia',
  'countries.IE': 'Ireland',
  'countries.IL': 'Israel',
  'countries.IM': 'Isle of Man',
  'countries.IN': 'India',
  'countries.IO': 'British Indian Ocean Territory',
  'countries.IQ': 'Iraq',
  'countries.IR': 'Iran',
  'countries.IS': 'Iceland',
  'countries.IT': 'Italy',
  'countries.JE': 'Jersey',
  'countries.JM': 'Jamaica',
  'countries.JO': 'Jordan',
  'countries.JP': 'Japan',
  'countries.KE': 'Kenya',
  'countries.KG': 'Kyrgyzstan',
  'countries.KH': 'Cambodia',
  'countries.KI': 'Kiribati',
  'countries.KM': 'Comoros',
  'countries.KN': 'Saint Kitts and Nevis',
  'countries.KP': 'Korea',
  'countries.KR': 'Korea',
  'countries.KW': 'Kuwait',
  'countries.KY': 'Cayman Islands',
  'countries.KZ': 'Kazakhstan',
  'countries.LA': "Lao People's Democratic Republic",
  'countries.LB': 'Lebanon',
  'countries.LC': 'Saint Lucia',
  'countries.LI': 'Liechtenstein',
  'countries.LK': 'Sri Lanka',
  'countries.LR': 'Liberia',
  'countries.LS': 'Lesotho',
  'countries.LT': 'Lithuania',
  'countries.LU': 'Luxembourg',
  'countries.LV': 'Latvia',
  'countries.LY': 'Libyan Arab Jamahiriya',
  'countries.MA': 'Morocco',
  'countries.MC': 'Monaco',
  'countries.MD': 'Moldova',
  'countries.ME': 'Montenegro',
  'countries.MF': 'Saint Martin',
  'countries.MG': 'Madagascar',
  'countries.MH': 'Marshall Islands',
  'countries.MK': 'Macedonia',
  'countries.ML': 'Mali',
  'countries.MM': 'Myanmar',
  'countries.MN': 'Mongolia',
  'countries.MO': 'Macao',
  'countries.MP': 'Northern Mariana Islands',
  'countries.MQ': 'Martinique',
  'countries.MR': 'Mauritania',
  'countries.MS': 'Montserrat',
  'countries.MT': 'Malta',
  'countries.MU': 'Mauritius',
  'countries.MV': 'Maldives',
  'countries.MW': 'Malawi',
  'countries.MX': 'Mexico',
  'countries.MY': 'Malaysia',
  'countries.MZ': 'Mozambique',
  'countries.NA': 'Namibia',
  'countries.NC': 'New Caledonia',
  'countries.NE': 'Niger',
  'countries.NF': 'Norfolk Island',
  'countries.NG': 'Nigeria',
  'countries.NI': 'Nicaragua',
  'countries.NL': 'Netherlands',
  'countries.NO': 'Norway',
  'countries.NP': 'Nepal',
  'countries.NR': 'Nauru',
  'countries.NU': 'Niue',
  'countries.NZ': 'New Zealand',
  'countries.O1': 'Other Country',
  'countries.OM': 'Oman',
  'countries.PA': 'Panama',
  'countries.PE': 'Peru',
  'countries.PF': 'French Polynesia',
  'countries.PG': 'Papua New Guinea',
  'countries.PH': 'Philippines',
  'countries.PK': 'Pakistan',
  'countries.PL': 'Poland',
  'countries.PM': 'Saint Pierre and Miquelon',
  'countries.PN': 'Pitcairn',
  'countries.PR': 'Puerto Rico',
  'countries.PS': 'Palestinian Territory',
  'countries.PT': 'Portugal',
  'countries.PW': 'Palau',
  'countries.PY': 'Paraguay',
  'countries.QA': 'Qatar',
  'countries.RE': 'Reunion',
  'countries.RO': 'Romania',
  'countries.RS': 'Serbia',
  'countries.RU': 'Russian Federation',
  'countries.RW': 'Rwanda',
  'countries.SA': 'Saudi Arabia',
  'countries.SB': 'Solomon Islands',
  'countries.SC': 'Seychelles',
  'countries.SD': 'Sudan',
  'countries.SE': 'Sweden',
  'countries.SG': 'Singapore',
  'countries.SH': 'Saint Helena',
  'countries.SI': 'Slovenia',
  'countries.SJ': 'Svalbard and Jan Mayen',
  'countries.SK': 'Slovakia',
  'countries.SL': 'Sierra Leone',
  'countries.SM': 'San Marino',
  'countries.SN': 'Senegal',
  'countries.SO': 'Somalia',
  'countries.SR': 'Suriname',
  'countries.ST': 'Sao Tome and Principe',
  'countries.SV': 'El Salvador',
  'countries.SY': 'Syrian Arab Republic',
  'countries.SZ': 'Swaziland',
  'countries.TC': 'Turks and Caicos Islands',
  'countries.TD': 'Chad',
  'countries.TF': 'French Southern Territories',
  'countries.TG': 'Togo',
  'countries.TH': 'Thailand',
  'countries.TJ': 'Tajikistan',
  'countries.TK': 'Tokelau',
  'countries.TL': 'Timor-Leste',
  'countries.TM': 'Turkmenistan',
  'countries.TN': 'Tunisia',
  'countries.TO': 'Tonga',
  'countries.TR': 'Turkey',
  'countries.TT': 'Trinidad and Tobago',
  'countries.TV': 'Tuvalu',
  'countries.TW': 'Taiwan',
  'countries.TZ': 'Tanzania',
  'countries.UA': 'Ukraine',
  'countries.UG': 'Uganda',
  'countries.UM': 'United States Minor Outlying Islands',
  'countries.US': 'United States',
  'countries.UY': 'Uruguay',
  'countries.UZ': 'Uzbekistan',
  'countries.VA': 'Holy See (Vatican City State)',
  'countries.VC': 'Saint Vincent and the Grenadines',
  'countries.VE': 'Venezuela',
  'countries.VG': 'Virgin Islands',
  'countries.VI': 'Virgin Islands',
  'countries.VN': 'Vietnam',
  'countries.VU': 'Vanuatu',
  'countries.WF': 'Wallis and Futuna',
  'countries.WS': 'Samoa',
  'countries.YE': 'Yemen',
  'countries.YT': 'Mayotte',
  'countries.ZA': 'South Africa',
  'countries.ZM': 'Zambia',
  'countries.ZW': 'Zimbabwe',
  'states.US.AL': 'Alabama',
  'states.US.AK': 'Alaska',
  'states.US.AS': 'American Samoa',
  'states.US.AZ': 'Arizona',
  'states.US.AR': 'Arkansas',
  'states.US.CA': 'California',
  'states.US.CO': 'Colorado',
  'states.US.CT': 'Connecticut',
  'states.US.DE': 'Delaware',
  'states.US.DC': 'District Of Columbia',
  'states.US.FM': 'Federated States Of Micronesia',
  'states.US.FL': 'Florida',
  'states.US.GA': 'Georgia',
  'states.US.GU': 'Guam',
  'states.US.HI': 'Hawaii',
  'states.US.ID': 'Idaho',
  'states.US.IL': 'Illinois',
  'states.US.IN': 'Indiana',
  'states.US.IA': 'Iowa',
  'states.US.KS': 'Kansas',
  'states.US.KY': 'Kentucky',
  'states.US.LA': 'Louisiana',
  'states.US.ME': 'Maine',
  'states.US.MH': 'Marshall Islands',
  'states.US.MD': 'Maryland',
  'states.US.MA': 'Massachusetts',
  'states.US.MI': 'Michigan',
  'states.US.MN': 'Minnesota',
  'states.US.MS': 'Mississippi',
  'states.US.MO': 'Missouri',
  'states.US.MT': 'Montana',
  'states.US.NE': 'Nebraska',
  'states.US.NV': 'Nevada',
  'states.US.NH': 'New Hampshire',
  'states.US.NJ': 'New Jersey',
  'states.US.NM': 'New Mexico',
  'states.US.NY': 'New York',
  'states.US.NC': 'North Carolina',
  'states.US.ND': 'North Dakota',
  'states.US.MP': 'Northern Mariana Islands',
  'states.US.OH': 'Ohio',
  'states.US.OK': 'Oklahoma',
  'states.US.OR': 'Oregon',
  'states.US.PW': 'Palau',
  'states.US.PA': 'Pennsylvania',
  'states.US.PR': 'Puerto Rico',
  'states.US.RI': 'Rhode Island',
  'states.US.SC': 'South Carolina',
  'states.US.SD': 'South Dakota',
  'states.US.TN': 'Tennessee',
  'states.US.TX': 'Texas',
  'states.US.UT': 'Utah',
  'states.US.VT': 'Vermont',
  'states.US.VI': 'Virgin Islands',
  'states.US.VA': 'Virginia',
  'states.US.WA': 'Washington',
  'states.US.WV': 'West Virginia',
  'states.US.WI': 'Wisconsin',
  'states.US.WY': 'Wyoming',
  'nationalities.AF': 'Afghan',
  'nationalities.AX': 'Aland Island',
  'nationalities.AL': 'Albanian',
  'nationalities.DZ': 'Algerian',
  'nationalities.AS': 'American Samoan',
  'nationalities.AD': 'Andorran',
  'nationalities.AO': 'Angolan',
  'nationalities.AI': 'Anguillan',
  'nationalities.AQ': 'Antarctic',
  'nationalities.AG': 'Antiguan',
  'nationalities.AR': 'Argentine',
  'nationalities.AM': 'Armenian',
  'nationalities.AW': 'Aruban',
  'nationalities.AU': 'Australian',
  'nationalities.AT': 'Austrian',
  'nationalities.AZ': 'Azerbaijani',
  'nationalities.BS': 'Bahamian',
  'nationalities.BH': 'Bahraini',
  'nationalities.BD': 'Bangladeshi',
  'nationalities.BB': 'Barbadian',
  'nationalities.BY': 'Belarusian',
  'nationalities.BE': 'Belgian',
  'nationalities.BZ': 'Belizean',
  'nationalities.BJ': 'Beninese',
  'nationalities.BM': 'Bermudan',
  'nationalities.BT': 'Bhutanese',
  'nationalities.BO': 'Bolivian',
  'nationalities.BA': 'Bosnian',
  'nationalities.BW': 'Botswanan',
  'nationalities.BV': 'Bouvet Island',
  'nationalities.BR': 'Brazilian',
  'nationalities.IO': 'BIOT',
  'nationalities.BN': 'Bruneian',
  'nationalities.BG': 'Bulgarian',
  'nationalities.BF': 'Burkinabe',
  'nationalities.BI': 'Burundian',
  'nationalities.KH': 'Cambodian',
  'nationalities.CM': 'Cameroonian',
  'nationalities.CA': 'Canadian',
  'nationalities.CV': 'Cabo Verdean',
  'nationalities.KY': 'Caymanian',
  'nationalities.CF': 'Central African',
  'nationalities.TD': 'Chadian',
  'nationalities.CL': 'Chilean',
  'nationalities.CN': 'Chinese',
  'nationalities.CX': 'Christmas Island',
  'nationalities.CC': 'Cocos Island',
  'nationalities.CO': 'Colombian',
  'nationalities.KM': 'Comoran',
  'nationalities.CD': 'Congolese',
  'nationalities.CG': 'Congolese',
  'nationalities.CK': 'Cook Island',
  'nationalities.CR': 'Costa Rican',
  'nationalities.CI': 'Ivorian',
  'nationalities.HR': 'Croatian',
  'nationalities.CU': 'Cuban',
  'nationalities.CY': 'Cypriot',
  'nationalities.CZ': 'Czech',
  'nationalities.DK': 'Danish',
  'nationalities.DJ': 'Djiboutian',
  'nationalities.DM': 'Dominican',
  'nationalities.DO': 'Dominican',
  'nationalities.EC': 'Ecuadorian',
  'nationalities.EG': 'Egyptian',
  'nationalities.SV': 'Salvadoran',
  'nationalities.GQ': 'Equatorial Guinean',
  'nationalities.ER': 'Eritrean',
  'nationalities.EE': 'Estonian',
  'nationalities.ET': 'Ethiopian',
  'nationalities.FK': 'Falkland Island',
  'nationalities.FO': 'Faroese',
  'nationalities.FJ': 'Fijian',
  'nationalities.FI': 'Finnish',
  'nationalities.FR': 'French',
  'nationalities.GF': 'French Guianese',
  'nationalities.PF': 'French Polynesian',
  'nationalities.TF': 'French Southern Territories',
  'nationalities.GA': 'Gabonese',
  'nationalities.GM': 'Gambian',
  'nationalities.GE': 'Georgian',
  'nationalities.DE': 'German',
  'nationalities.GH': 'Ghanaian',
  'nationalities.GI': 'Gibraltar',
  'nationalities.GR': 'Greek',
  'nationalities.GL': 'Greenlandic',
  'nationalities.GD': 'Grenadian',
  'nationalities.GP': 'Guadeloupe',
  'nationalities.GU': 'Guamanian',
  'nationalities.GT': 'Guatemalan',
  'nationalities.GG': 'Sarnian',
  'nationalities.GN': 'Guinean',
  'nationalities.GW': 'Bissau-Guinean',
  'nationalities.GY': 'Guyanese',
  'nationalities.HT': 'Haitian',
  'nationalities.HM': 'Heard Island',
  'nationalities.VA': 'Vatican',
  'nationalities.HN': 'Honduran',
  'nationalities.HK': 'Hong Konger',
  'nationalities.HU': 'Hungarian',
  'nationalities.IS': 'Icelandic',
  'nationalities.IN': 'Indian',
  'nationalities.ID': 'Indonesian',
  'nationalities.IR': 'Iranian',
  'nationalities.IQ': 'Iraqi',
  'nationalities.IE': 'Irish',
  'nationalities.IM': 'Manx',
  'nationalities.IL': 'Israeli',
  'nationalities.IT': 'Italian',
  'nationalities.JM': 'Jamaican',
  'nationalities.JP': 'Japanese',
  'nationalities.JE': 'Jersiais',
  'nationalities.JO': 'Jordanian',
  'nationalities.KZ': 'Kazakhstani',
  'nationalities.KE': 'Kenyan',
  'nationalities.KI': 'I-Kiribati',
  'nationalities.KW': 'Kuwaiti',
  'nationalities.KG': 'Kyrgyzstani',
  'nationalities.LA': 'Lao',
  'nationalities.LV': 'Latvian',
  'nationalities.LB': 'Lebanese',
  'nationalities.LS': 'Basotho',
  'nationalities.LR': 'Liberian',
  'nationalities.LY': 'Libyan',
  'nationalities.LI': 'Liechtensteiner',
  'nationalities.LT': 'Lithuanian',
  'nationalities.LU': 'Luxembourg',
  'nationalities.MO': 'Macanese',
  'nationalities.MK': 'Macedonian',
  'nationalities.MG': 'Malagasy',
  'nationalities.MW': 'Malawian',
  'nationalities.MY': 'Malaysian',
  'nationalities.MV': 'Maldivian',
  'nationalities.ML': 'Malian',
  'nationalities.MT': 'Maltese',
  'nationalities.MH': 'Marshallese',
  'nationalities.MQ': 'Martinican',
  'nationalities.MR': 'Mauritanian',
  'nationalities.MU': 'Mauritian',
  'nationalities.YT': 'Mahoran',
  'nationalities.MX': 'Mexican',
  'nationalities.FM': 'Micronesian',
  'nationalities.MD': 'Moldovan',
  'nationalities.MC': 'Monegasque',
  'nationalities.MN': 'Mongolian',
  'nationalities.ME': 'Montenegrin',
  'nationalities.MF': 'Saint-Martinoise',
  'nationalities.MS': 'Montserratian',
  'nationalities.MA': 'Moroccan',
  'nationalities.MZ': 'Mozambican',
  'nationalities.MM': 'Myanma',
  'nationalities.NA': 'Namibian',
  'nationalities.NR': 'Nauruan',
  'nationalities.NP': 'Nepalese',
  'nationalities.NL': 'Dutch',
  'nationalities.AN': 'Netherlands Antillean',
  'nationalities.NC': 'New Caledonian',
  'nationalities.NZ': 'New Zealander',
  'nationalities.NI': 'Nicaraguan',
  'nationalities.NE': 'Nigerien',
  'nationalities.NG': 'Nigerian',
  'nationalities.NU': 'Niuean',
  'nationalities.NF': 'Norfolk Island',
  'nationalities.KP': 'North Korean',
  'nationalities.MP': 'Northern Marianan',
  'nationalities.NO': 'Norwegian',
  'nationalities.OM': 'Omani',
  'nationalities.PK': 'Pakistani',
  'nationalities.PW': 'Palauan',
  'nationalities.PS': 'Palestinian',
  'nationalities.PA': 'Panamanian',
  'nationalities.PG': 'Papuan',
  'nationalities.PY': 'Paraguayan',
  'nationalities.PE': 'Peruvian',
  'nationalities.PH': 'Filipino',
  'nationalities.PN': 'Pitcairn Island',
  'nationalities.PL': 'Polish',
  'nationalities.PT': 'Portuguese',
  'nationalities.PR': 'Puerto Rican',
  'nationalities.QA': 'Qatari',
  'nationalities.RE': 'Reunionese',
  'nationalities.RO': 'Romanian',
  'nationalities.RU': 'Russian',
  'nationalities.RW': 'Rwandan',
  'nationalities.SH': 'Saint Helenian',
  'nationalities.KN': 'Kittitian',
  'nationalities.LC': 'Saint Lucian',
  'nationalities.PM': 'Saint-Pierrais',
  'nationalities.VC': 'Saint Vincentian',
  'nationalities.WS': 'Samoan',
  'nationalities.SM': 'Sammarinese',
  'nationalities.ST': 'Sao Tomean',
  'nationalities.SA': 'Saudi',
  'nationalities.SN': 'Senegalese',
  'nationalities.RS': 'Serbian',
  'nationalities.SC': 'Seychellois',
  'nationalities.SL': 'Sierra Leonean',
  'nationalities.SG': 'Singaporean',
  'nationalities.SK': 'Slovak',
  'nationalities.SI': 'Slovenian',
  'nationalities.SB': 'Solomon Island',
  'nationalities.SO': 'Somali',
  'nationalities.ZA': 'South African',
  'nationalities.GS': 'South Georgia Island',
  'nationalities.KR': 'South Korean',
  'nationalities.ES': 'Spanish',
  'nationalities.LK': 'Sri Lankan',
  'nationalities.SD': 'Sudanese',
  'nationalities.SR': 'Surinamese',
  'nationalities.SJ': 'Svalbard',
  'nationalities.SZ': 'Swazi',
  'nationalities.SE': 'Swedish',
  'nationalities.CH': 'Swiss',
  'nationalities.SY': 'Syrian',
  'nationalities.TW': 'Taiwanese',
  'nationalities.TJ': 'Tajikistani',
  'nationalities.TZ': 'Tanzanian',
  'nationalities.TH': 'Thai',
  'nationalities.TL': 'Timorese',
  'nationalities.TG': 'Togolese',
  'nationalities.TK': 'Tokelauan',
  'nationalities.TO': 'Tongan',
  'nationalities.TT': 'Trinidadian',
  'nationalities.TN': 'Tunisian',
  'nationalities.TR': 'Turkish',
  'nationalities.TM': 'Turkmen',
  'nationalities.TC': 'Turks and Caicos Island',
  'nationalities.TV': 'Tuvaluan',
  'nationalities.UG': 'Ugandan',
  'nationalities.UA': 'Ukrainian',
  'nationalities.AE': 'Emirati',
  'nationalities.GB': 'British',
  'nationalities.US': 'American',
  'nationalities.UM': 'US Minor Outlying Island',
  'nationalities.UY': 'Uruguayan',
  'nationalities.UZ': 'Uzbekistani',
  'nationalities.VU': 'Ni-Vanuatu',
  'nationalities.VE': 'Venezuelan',
  'nationalities.VN': 'Vietnamese',
  'nationalities.VG': 'British Virgin Island',
  'nationalities.VI': 'US Virgin Island',
  'nationalities.WF': 'Wallisian',
  'nationalities.EH': 'Sahrawi',
  'nationalities.YE': 'Yemeni',
  'nationalities.ZM': 'Zambian',
  'nationalities.ZW': 'Zimbabwean',
  'month.1': 'January',
  'month.2': 'February',
  'month.3': 'March',
  'month.4': 'April',
  'month.5': 'May',
  'month.6': 'June',
  'month.7': 'July',
  'month.8': 'August',
  'month.9': 'September',
  'month.10': 'October',
  'month.11': 'November',
  'month.12': 'December',
  'filters.art.price.0.label': '0',
  'filters.art.price.0.helper': '0',
  'filters.art.price.100.label': ' ',
  'filters.art.price.100.helper': '100',
  'filters.art.price.200.label': '200',
  'filters.art.price.200.helper': '200',
  'filters.art.price.300.label': ' ',
  'filters.art.price.300.helper': '300',
  'filters.art.price.500.label': '500',
  'filters.art.price.500.helper': '500',
  'filters.art.price.1000.label': ' ',
  'filters.art.price.1000.helper': '1000',
  'filters.art.price.2000.label': '2000',
  'filters.art.price.2000.helper': '2000',
  'filters.art.price.5000.label': ' ',
  'filters.art.price.5000.helper': '5000',
  'filters.art.price.10000.label': ' ',
  'filters.art.price.10000.helper': '10000',
  'filters.art.price.UP.label': 'Max',
  'filters.art.price.UP.helper': 'Maximum',
  'filters.art.color.colourful': 'Colourful',
  'filters.art.color.black-white': 'Black & White',
  'filters.art.color.white': 'White',
  'filters.art.color.grey': 'Grey',
  'filters.art.color.black': 'Black',
  'filters.art.color.maroon-neutral': 'Maroon Neutral',
  'filters.art.color.orange': 'Orange',
  'filters.art.color.yellow': 'Yellow',
  'filters.art.color.green': 'Green',
  'filters.art.color.blue': 'Blue',
  'filters.art.color.purple': 'Purple',
  'filters.art.color.pink': 'Pink',
  'filters.art.color.red': 'Red',
  'filters.art.medium.collage': 'Collage',
  'filters.art.medium.digital': 'Digital',
  'filters.art.medium.drawings': 'Drawings',
  'filters.art.medium.installation': 'Installation',
  'filters.art.medium.paintings': 'Paintings',
  'filters.art.medium.photography': 'Photography',
  'filters.art.medium.prints': 'Prints',
  'filters.art.medium.sculpture': 'Sculpture',
  'filters.art.subMedium.photo': 'Photo',
  'filters.art.subMedium.mixed-media-2': 'Mixed Media',
  'filters.art.subMedium.oil': 'Oil',
  'filters.art.subMedium.digital': 'Digital',
  'filters.art.subMedium.screenprint': 'Screenprint',
  'filters.art.subMedium.transfer': 'Transfer',
  'filters.art.subMedium.fabric': 'Fabric',
  'filters.art.subMedium.fabric-1': 'Fabric',
  'filters.art.subMedium.mixed-media-1': 'Mixed Media',
  'filters.art.subMedium.paper-1': 'Paper',
  'filters.art.subMedium.chalk': 'Chalk',
  'filters.art.subMedium.charcoal': 'Charcoal',
  'filters.art.subMedium.ink': 'Ink',
  'filters.art.subMedium.ink-1': 'Ink',
  'filters.art.subMedium.pastel': 'Pastel',
  'filters.art.subMedium.pencil': 'Pencil',
  'filters.art.subMedium.acrylic': 'Acrylic',
  'filters.art.subMedium.airbrush': 'Airbrush',
  'filters.art.subMedium.gouache': 'Gouache',
  'filters.art.subMedium.tempera': 'Tempera',
  'filters.art.subMedium.watercolour': 'Watercolour',
  'filters.art.subMedium.black-white': 'Black & White',
  'filters.art.subMedium.crayon': 'Crayon',
  'filters.art.subMedium.colour': 'Colour',
  'filters.art.subMedium.digital-1': 'Digital',
  'filters.art.subMedium.mixed-media': 'Mixed media',
  'filters.art.subMedium.encaustic': 'Encaustic',
  'filters.art.subMedium.etching': 'Etching',
  'filters.art.subMedium.lino': 'Lino',
  'filters.art.subMedium.lithograph': 'Lithograph',
  'filters.art.subMedium.marble': 'Marble',
  'filters.art.subMedium.mixed-media-3': 'Mixed media',
  'filters.art.subMedium.mono': 'Mono',
  'filters.art.subMedium.woodcut': 'Woodcut',
  'filters.art.subMedium.bronze': 'Bronze',
  'filters.art.subMedium.ceramic': 'Ceramic',
  'filters.art.subMedium.clay': 'Clay',
  'filters.art.subMedium.enamel': 'Enamel',
  'filters.art.subMedium.glass': 'Glass',
  'filters.art.subMedium.kinetic': 'Kinetic',
  'filters.art.subMedium.metal': 'Metal',
  'filters.art.subMedium.paper': 'Paper',
  'filters.art.subMedium.pinhole': 'Pinhole',
  'filters.art.subMedium.plastic': 'Plastic',
  'filters.art.subMedium.resin': 'Resin',
  'filters.art.subMedium.vector': 'Vector',
  'filters.art.subMedium.wood': 'Wood',
  'filters.art.subMedium.stone': 'Stone',
  'filters.art.stage.new': 'New & Noteworthy',
  'filters.art.stage.emerging': 'Emerging',
  'filters.art.stage.established': 'Established',
  'filters.art.orientation.L': 'Landscape',
  'filters.art.orientation.P': 'Portrait',
  'filters.art.orientation.S': 'Square',
  'filters.art.size.S.label': 'S',
  'filters.art.size.S.helper': 'The longest side is up to {value}',
  'filters.art.size.M.label': 'M',
  'filters.art.size.M.helper': 'The longest side is up to {value}',
  'filters.art.size.L.label': 'L',
  'filters.art.size.L.helper': 'The longest side is up to {value}',
  'filters.art.size.XL.label': 'XL',
  'filters.art.size.XL.helper': 'The longest side is up to {value}',
  'filters.art.size.XXL.label': 'XXL',
  'filters.art.size.XXL.helper': 'The longest side is over {value}',
  'filters.art.style.abstract': 'Abstract',
  'filters.art.style.abstract-expressionism': 'Abstract Expressionism',
  'filters.art.style.abstract-marble': 'Marble',
  'filters.art.style.art-deco': 'Art deco',
  'filters.art.style.cartoon': 'Cartoon',
  'filters.art.style.conceptual': 'Conceptual',
  'filters.art.style.colour-field': 'Colour Field',
  'filters.art.style.cubism': 'Cubism',
  'filters.art.style.geometric-abstract': 'Geometric Abstract',
  'filters.art.style.dada': 'Dada',
  'filters.art.style.expressionistic': 'Expressionistic',
  'filters.art.style.graphic': 'Graphic',
  'filters.art.style.minimalistic': 'Minimalistic',
  'filters.art.style.mosaic': 'Mosaic',
  'filters.art.style.figurative': 'Figurative',
  'filters.art.style.geometric': 'Geometric',
  'filters.art.style.illustrative': 'Illustrative',
  'filters.art.style.impressionistic': 'Impressionistic',
  'filters.art.style.photorealism': 'Photorealism',
  'filters.art.style.pop-art': 'Pop Art',
  'filters.art.style.realistic': 'Realistic',
  'filters.art.style.stencil': 'Stencil',
  'filters.art.style.street-art': 'Street Art',
  'filters.art.style.surrealist': 'Surrealist',
  'filters.art.style.other': 'Other',
  'filters.art.subject.architecture': 'Architecture',
  'filters.art.subject.botanical': 'Botanical',
  'filters.art.subject.celebrities': 'Celebrities',
  'filters.art.subject.documentary': 'Documentary',
  'filters.art.subject.other': 'Other',
  'filters.art.subject.science-technology': 'Science & Technology',
  'filters.art.subject.seascapes': 'Seascapes',
  'filters.art.subject.transport-auto': 'Transport & Auto',
  'filters.art.subject.typography': 'Typography',
  'filters.art.subject.urban': 'Urban',
  'filters.art.subject.maps': 'Maps',
  'filters.art.subject.fantasy': 'Fantasy',
  'filters.art.subject.food': 'Food',
  'filters.art.subject.abstract': 'Abstract',
  'filters.art.subject.animals': 'Animals',
  'filters.art.subject.cityscapes': 'Cityscapes',
  'filters.art.subject.fashion': 'Fashion',
  'filters.art.subject.landscapes': 'Landscapes',
  'filters.art.subject.music': 'Music',
  'filters.art.subject.nature': 'Nature',
  'filters.art.subject.nudes': 'Nudes',
  'filters.art.subject.politics': 'Politics',
  'filters.art.subject.portraits': 'Portraits',
  'filters.art.subject.spirituality': 'Spirituality',
  'filters.art.subject.sports': 'Sports',
  'filters.art.subject.still-life': 'Still life',
  'filters.art.subject.underwater': 'Underwater',
  'filters.art.subject.wildlife': 'Wildlife',
  'filters.art.subSubject.bird': 'Birds',
  'filters.art.subSubject.cat': 'Cats',
  'filters.art.subSubject.cow': 'Cows',
  'filters.art.subSubject.dog': 'Dogs',
  'filters.art.subSubject.fish': 'Fish',
  'filters.art.subSubject.horse': 'Horses',
  'filters.art.subSubject.hong-kong': 'Hong Kong',
  'filters.art.subSubject.london-1': 'London',
  'filters.art.subSubject.new-york-1': 'New York',
  'filters.art.subSubject.paris': 'Paris',
  'filters.art.subSubject.singapore': 'Singapore',
  'filters.art.subSubject.beach': 'Beach',
  'filters.art.subSubject.rural': 'Rural',
  'filters.art.subSubject.sky': 'Sky',
  'filters.art.subSubject.london': 'London',
  'filters.art.subSubject.new-york': 'New York',
  'filters.art.subSubject.flower': 'Flowers',
  'filters.art.subSubject.tree': 'Trees',
  'filters.art.subSubject.leaves': 'Leaves',
  'filters.art.subSubject.female': 'Female',
  'filters.art.subSubject.male': 'Male',
  'filters.art.subSubject.religion': 'Religion',
  'filters.art.subSubject.athletics': 'Athletics',
  'filters.art.subSubject.cycling': 'Cycling',
  'filters.art.subSubject.football': 'Football',
  'filters.art.subSubject.flower-1': 'Flowers',
  'filters.art.subSubject.food': 'Food',
  'filters.art.subSubject.airplane': 'Airplanes',
  'filters.art.subSubject.bike': 'Bikes',
  'filters.art.subSubject.car': 'Cars',
  'filters.art.subSubject.ship': 'Ship',
  'filters.art.subSubject.train': 'Trains',
  'filters.art.subSubject.yacht': 'Yacht',
  'filters.art.width.UP.label': 'Max',
  'filters.art.width.UP.helper': 'Maximum',
  'filters.art.height.UP.label': 'Max',
  'filters.art.height.UP.helper': 'Maximum',
  'filters.art.breadcrumbs.medium.collage': 'Collage',
  'filters.art.breadcrumbs.medium.digital': 'Digital',
  'filters.art.breadcrumbs.medium.drawings': 'Drawings',
  'filters.art.breadcrumbs.medium.installation': 'Installation',
  'filters.art.breadcrumbs.medium.paintings': 'Paintings',
  'filters.art.breadcrumbs.medium.photography': 'Photography',
  'filters.art.breadcrumbs.medium.prints': 'Prints',
  'filters.art.breadcrumbs.medium.sculpture': 'Sculpture',
  'filters.art.breadcrumbs.style.abstract': 'Abstract',
  'filters.art.breadcrumbs.style.abstract-expressionism': 'Abstract Expressionism',
  'filters.art.breadcrumbs.style.abstract-marble': 'Marble',
  'filters.art.breadcrumbs.style.art-deco': 'Art deco',
  'filters.art.breadcrumbs.style.cartoon': 'Cartoon',
  'filters.art.breadcrumbs.style.conceptual': 'Conceptual',
  'filters.art.breadcrumbs.style.colour-field': 'Colour Field',
  'filters.art.breadcrumbs.style.cubism': 'Cubism',
  'filters.art.breadcrumbs.style.geometric-abstract': 'Geometric Abstract',
  'filters.art.breadcrumbs.style.dada': 'Dada',
  'filters.art.breadcrumbs.style.expressionistic': 'Expressionistic',
  'filters.art.breadcrumbs.style.graphic': 'Graphic',
  'filters.art.breadcrumbs.style.minimalistic': 'Minimalistic',
  'filters.art.breadcrumbs.style.mosaic': 'Mosaic',
  'filters.art.breadcrumbs.style.figurative': 'Figurative',
  'filters.art.breadcrumbs.style.geometric': 'Geometric',
  'filters.art.breadcrumbs.style.illustrative': 'Illustrative',
  'filters.art.breadcrumbs.style.impressionistic': 'Impressionistic',
  'filters.art.breadcrumbs.style.photorealism': 'Photorealism',
  'filters.art.breadcrumbs.style.pop-art': 'Pop Art',
  'filters.art.breadcrumbs.style.realistic': 'Realistic',
  'filters.art.breadcrumbs.style.stencil': 'Stencil',
  'filters.art.breadcrumbs.style.street-art': 'Street Art',
  'filters.art.breadcrumbs.style.surrealist': 'Surrealist',
  'filters.art.breadcrumbs.style.other': 'Other',
  'filters.collection.medium.collage': 'Collage',
  'filters.collection.medium.drawings': 'Drawings',
  'filters.collection.medium.installation': 'Installation',
  'filters.collection.medium.paintings': 'Paintings',
  'filters.collection.medium.photography': 'Photography',
  'filters.collection.medium.prints': 'Prints',
  'filters.collection.medium.sculpture': 'Sculpture',
  'filters.collection.style.abstract': 'Abstract',
  'filters.collection.style.art-deco': 'Art deco',
  'filters.collection.style.cartoon': 'Cartoon',
  'filters.collection.style.conceptual': 'Conceptual',
  'filters.collection.style.cubism': 'Cubism',
  'filters.collection.style.dada': 'Dada',
  'filters.collection.style.expressionistic': 'Expressionistic',
  'filters.collection.style.minimalistic': 'Minimalistic',
  'filters.collection.style.figurative': 'Figurative',
  'filters.collection.style.geometric': 'Geometric',
  'filters.collection.style.illustrative': 'Illustrative',
  'filters.collection.style.impressionistic': 'Impressionistic',
  'filters.collection.style.pop-art': 'Pop Art',
  'filters.collection.style.realistic': 'Realistic',
  'filters.collection.style.street-art': 'Street Art',
  'filters.collection.style.surrealist': 'Surrealist',
  'filters.collection.subject.architecture': 'Architecture',
  'filters.collection.subject.botanical': 'Botanical',
  'filters.collection.subject.celebrities': 'Celebrities',
  'filters.collection.subject.documentary': 'Documentary',
  'filters.collection.subject.science-technology': 'Science & Technology',
  'filters.collection.subject.seascapes': 'Seascapes',
  'filters.collection.subject.transport-auto': 'Transport & Auto',
  'filters.collection.subject.typography': 'Typography',
  'filters.collection.subject.urban': 'Urban',
  'filters.collection.subject.maps': 'Maps',
  'filters.collection.subject.food': 'Food',
  'filters.collection.subject.animals': 'Animals',
  'filters.collection.subject.cityscapes': 'Cityscapes',
  'filters.collection.subject.fashion': 'Fashion',
  'filters.collection.subject.landscapes': 'Landscapes',
  'filters.collection.subject.nudes': 'Nudes',
  'filters.collection.subject.plants-flowers': 'Plants & Flowers',
  'filters.collection.subject.portraits': 'Portraits',
  'filters.collection.subject.spirituality': 'Spirituality',
  'filters.collection.subject.sports': 'Sports',
  'filters.collection.subject.still-life': 'Still life',
  'filters.collection.breadcrumbs.medium.collage': 'Collage',
  'filters.collection.breadcrumbs.medium.drawings': 'Drawings',
  'filters.collection.breadcrumbs.medium.installation': 'Installation',
  'filters.collection.breadcrumbs.medium.paintings': 'Paintings',
  'filters.collection.breadcrumbs.medium.photography': 'Photography',
  'filters.collection.breadcrumbs.medium.prints': 'Prints',
  'filters.collection.breadcrumbs.medium.sculpture': 'Sculpture',
  'filters.collection.breadcrumbs.style.abstract': 'Abstract',
  'filters.collection.breadcrumbs.style.art-deco': 'Art deco',
  'filters.collection.breadcrumbs.style.cartoon': 'Cartoon',
  'filters.collection.breadcrumbs.style.conceptual': 'Conceptual',
  'filters.collection.breadcrumbs.style.cubism': 'Cubism',
  'filters.collection.breadcrumbs.style.dada': 'Dada',
  'filters.collection.breadcrumbs.style.expressionistic': 'Expressionistic',
  'filters.collection.breadcrumbs.style.minimalistic': 'Minimalistic',
  'filters.collection.breadcrumbs.style.figurative': 'Figurative',
  'filters.collection.breadcrumbs.style.geometric': 'Geometric',
  'filters.collection.breadcrumbs.style.illustrative': 'Illustrative',
  'filters.collection.breadcrumbs.style.impressionistic': 'Impressionistic',
  'filters.collection.breadcrumbs.style.pop-art': 'Pop Art',
  'filters.collection.breadcrumbs.style.realistic': 'Realistic',
  'filters.collection.breadcrumbs.style.street-art': 'Street Art',
  'filters.collection.breadcrumbs.style.surrealist': 'Surrealist',
  'filters.artist.medium.collagists': 'Collagists',
  'filters.artist.medium.digital': 'Digital Artists',
  'filters.artist.medium.draftspersons': 'Draftspeople',
  'filters.artist.medium.installation': 'Installation Artists',
  'filters.artist.medium.painters': 'Painters',
  'filters.artist.medium.photographers': 'Photographers',
  'filters.artist.medium.printmakers': 'Printmakers',
  'filters.artist.medium.sculptors': 'Sculptors',
  'filters.artist.style.abstract': 'Abstract',
  'filters.artist.style.abstract-expressionism': 'Abstract Expressionism',
  'filters.artist.style.art-deco': 'Art deco',
  'filters.artist.style.cartoon': 'Cartoon',
  'filters.artist.style.colour-field': 'Colour Field',
  'filters.artist.style.conceptual': 'Conceptual',
  'filters.artist.style.cubism': 'Cubism',
  'filters.artist.style.dada': 'Dada',
  'filters.artist.style.expressionistic': 'Expressionistic',
  'filters.artist.style.figurative': 'Figurative',
  'filters.artist.style.graphic': 'Graphic',
  'filters.artist.style.geometric': 'Geometric',
  'filters.artist.style.geometric-abstract': 'Geometric Abstract',
  'filters.artist.style.illustrative': 'Illustrative',
  'filters.artist.style.impressionistic': 'Impressionistic',
  'filters.artist.style.abstract-marble': 'Marble',
  'filters.artist.style.minimalistic': 'Minimalistic',
  'filters.artist.style.mosaic': 'Mosaic',
  'filters.artist.style.photorealism': 'Photorealism',
  'filters.artist.style.pop-art': 'Pop Art',
  'filters.artist.style.realistic': 'Realistic',
  'filters.artist.style.stencil': 'Stencil',
  'filters.artist.style.street-art': 'Street Art',
  'filters.artist.style.surrealist': 'Surrealist',
  'filters.artist.region.african': 'African',
  'filters.artist.region.north-american': 'North American',
  'filters.artist.region.latin-american': 'Latin American',
  'filters.artist.region.caribbean': 'Caribbean',
  'filters.artist.region.european': 'European',
  'filters.artist.region.scandinavian': 'Scandinavian',
  'filters.artist.region.easter-european': 'Eastern European',
  'filters.artist.region.asian': 'Asian',
  'filters.artist.region.south-asian': 'South Asian',
  'filters.artist.region.middle-eastern': 'Middle Eastern',
  'filters.artist.region.oceanian': 'Australian and Oceanian',
  'filters.artist.gender.M': 'M',
  'filters.artist.gender.F': 'F',
  'filters.artist.stage.new': 'New & Noteworthy',
  'filters.artist.stage.emerging': 'Emerging',
  'filters.artist.stage.established': 'Established',
  'filters.artist.breadcrumbs.medium.collagists': 'Collagists',
  'filters.artist.breadcrumbs.medium.digital': 'Digital Artists',
  'filters.artist.breadcrumbs.medium.draftspersons': 'Draftspeople',
  'filters.artist.breadcrumbs.medium.installation': 'Installation Artists',
  'filters.artist.breadcrumbs.medium.painters': 'Painters',
  'filters.artist.breadcrumbs.medium.photographers': 'Photographers',
  'filters.artist.breadcrumbs.medium.printmakers': 'Printmakers',
  'filters.artist.breadcrumbs.medium.sculptors': 'Sculptors',
  'filters.artist.breadcrumbs.style.abstract': 'Abstract',
  'filters.artist.breadcrumbs.style.abstract-expressionism': 'Abstract Expressionism',
  'filters.artist.breadcrumbs.style.art-deco': 'Art deco',
  'filters.artist.breadcrumbs.style.cartoon': 'Cartoon',
  'filters.artist.breadcrumbs.style.colour-field': 'Colour Field',
  'filters.artist.breadcrumbs.style.conceptual': 'Conceptual',
  'filters.artist.breadcrumbs.style.cubism': 'Cubism',
  'filters.artist.breadcrumbs.style.dada': 'Dada',
  'filters.artist.breadcrumbs.style.expressionistic': 'Expressionistic',
  'filters.artist.breadcrumbs.style.figurative': 'Figurative',
  'filters.artist.breadcrumbs.style.graphic': 'Graphic',
  'filters.artist.breadcrumbs.style.geometric': 'Geometric',
  'filters.artist.breadcrumbs.style.geometric-abstract': 'Geometric Abstract',
  'filters.artist.breadcrumbs.style.illustrative': 'Illustrative',
  'filters.artist.breadcrumbs.style.impressionistic': 'Impressionistic',
  'filters.artist.breadcrumbs.style.abstract-marble': 'Marble',
  'filters.artist.breadcrumbs.style.minimalistic': 'Minimalistic',
  'filters.artist.breadcrumbs.style.mosaic': 'Mosaic',
  'filters.artist.breadcrumbs.style.photorealism': 'Photorealism',
  'filters.artist.breadcrumbs.style.pop-art': 'Pop Art',
  'filters.artist.breadcrumbs.style.realistic': 'Realistic',
  'filters.artist.breadcrumbs.style.stencil': 'Stencil',
  'filters.artist.breadcrumbs.style.street-art': 'Street Art',
  'filters.artist.breadcrumbs.style.surrealist': 'Surrealist',
  'filters.artist.singular.medium.collagists': 'Collagist',
  'filters.artist.singular.medium.digital': 'Digital Artist',
  'filters.artist.singular.medium.draftspersons': 'Draftsperson',
  'filters.artist.singular.medium.installation': 'Installation Artist',
  'filters.artist.singular.medium.painters': 'Painter',
  'filters.artist.singular.medium.photographers': 'Photographer',
  'filters.artist.singular.medium.printmakers': 'Printmaker',
  'filters.artist.singular.medium.sculptors': 'Sculptor',
  'brands.sohoHome': 'Soho Home',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.ok': 'OK',
  'common.or': 'or',
  'common.findOutMore': 'Find out more ',
  'common.here': 'here',
  'common.to': 'to',
  'common.by': 'by',
  'common.change': 'Change',
  'common.edit': 'Edit',
  'common.wishlist': 'Wishlist',
  'common.addToWishlist': 'Add to wishlist',
  'common.removeFromWishlist': 'Remove from wishlist',
  'common.yourWishlist': 'Your wishlist',
  'common.account': 'Account',
  'common.apply': 'Apply',
  'common.remove': 'Remove',
  'common.read_more': 'Read more',
  'common.read_less': 'Read less',
  'common.show_more': 'Show more',
  'common.show_less': 'Show less',
  'common.learnMore': 'Learn More',
  'common.previous': 'Previous',
  'common.next': 'Next',
  'common.share': 'Share',
  'common.follow': 'Follow',
  'common.unfollow': 'Unfollow',
  'common.enquire': 'enquire',
  'common.day': 'Day',
  'common.month': 'Month',
  'common.year': 'Year',
  'common.gender.M': 'Male',
  'common.gender.F': 'Female',
  'common.durations.month.full': 'month',
  'common.durations.month.abbreviated': 'mo',
  'common.birthdayAbbreviated': 'B.',
  'common.timediff.lessThanMin': 'less than a minute',
  'common.timediff.minutes': '{minutes, plural, one {# minute ago} other {# minutes ago}}',
  'common.timediff.hours': '{hours, plural, one {# hour ago} other {# hours ago}}',
  'common.timediff.days': '{days, plural, one {# day ago} other {# days ago}}',
  'common.timediff.weeks': '{weeks, plural, one {# week ago} other {# weeks ago}}',
  'common.timediff.months': '{months, plural, one {# month ago} other {# months ago}}',
  'common.timediff.moreThanYear': 'more than an year ago',
  'common.you': 'you',
  'common.objectByOwner': '{object} by {owner}',
  'common.byName': 'By {name}',
  'common.byNameLowercase': 'by {name}',
  'common.byNameLowercaseLink': 'by <link>{name}</link>',
  'common.rssFeed': 'RSS Feed',
  'common.viewMore': 'View More',
  'common.reviews': 'Rise Art Reviews',
  'common.unitCm': '{value}cm',
  'common.unitIn': '{value}in',
  'common.artistName': '{name} Artist',
  'common.date.formats.weekdayDayMonthYear': '{weekday} {day} {month} {year}',
  'common.date.formats.weekdayDayMonth': '{weekday} {day} {month}',
  'common.date.formats.weekday': '{weekday}',
  'common.date.options.short.month': '{value}',
  'common.date.options.short.weekday': '{value}',
  'common.moreInfo': 'More Info',
  'common.kb': 'kb',
  'components.activity.listTitle': 'Latest Activity',
  'components.activity.trending.title': 'Collectors have their eyes on',
  'components.activity.trending.subTitle': 'Your Real-time Activity Hub',
  'components.activity.trending.labels.product-sold': 'just sold',
  'components.activity.trending.labels.product-rental': 'just rented',
  'components.activity.trending.labels.favorite-product': 'wishlisted',
  'components.activity.trending.labels.cart-add': 'added to basket',
  'components.activity.trending.labels.artist-shortlisted': 'new artist',
  'components.activity.trending.labels.follow-shortlisted': 'followed',
  'components.activity.renderers.cart-add': '{objectByOwner} has been added to the basket',
  'components.activity.renderers.cart-add-creator-me':
    '{objectByOwner} has been added to the basket',
  'components.activity.renderers.product-sold': '{objectByOwner} has been sold',
  'components.activity.renderers.product-sold-creator-me': '{objectByOwner} has been sold',
  'components.activity.renderers.product-rental': '{objectByOwner} has been rented',
  'components.activity.renderers.product-rental-creator-me': '{objectByOwner} has been rented',
  'components.activity.renderers.favorite-product': '{objectByOwner} has been wishlisted',
  'components.activity.renderers.favorite-product-creator-me':
    '{objectByOwner} has been wishlisted',
  'components.activity.renderers.follow-shortlisted': '{creatorName} is now following {object}',
  'components.activity.renderers.follow-shortlisted-creator-me': 'You are now following {object}',
  'components.activity.renderers.product-available':
    '{creatorName} made {object} available for sale',
  'components.activity.renderers.product-available-creator-me':
    'You made {object} available for sale',
  'components.activity.renderers.event-created': '{creatorName} created the event {object}',
  'components.activity.renderers.event-created-creator-me': 'You created the event {object}',
  'components.activity.renderers.event-updated': '{creatorName} updated the event {object}',
  'components.activity.renderers.event-updated-creator-me': 'You updated the event {object}',
  'components.activity.renderers.event-canceled': '{creatorName} cancelled the event {object}',
  'components.activity.renderers.event-canceled-creator-me': 'You cancelled the event {object}',
  'components.activity.renderers.event-rsvp-yes': '{creatorName} is now attending {object}',
  'components.activity.renderers.event-rsvp-yes-creator-me': 'You are now attending {object}',
  'components.activity.renderers.article-created': '{creatorName} wrote an article {object}',
  'components.activity.renderers.article-created-creator-me': 'You wrote an article {object}',
  'components.activity.renderers.collection-created': '{creatorName} curated a collection {object}',
  'components.activity.renderers.collection-created-creator-me':
    'You curated a collection {object}',
  'components.breadcrumbs.home': 'Buy Fine Art Online',
  'components.breadcrumbs.blog': 'Editorial',
  'components.breadcrumbs.guides': 'Guides',
  'components.breadcrumbs.art': 'All Art',
  'components.breadcrumbs.artist': 'All Artists',
  'components.breadcrumbs.collection': 'All Collections',
  'components.breadcrumbs.activityList': 'Latest Activity',
  'components.breadcrumbs.eventList': 'Events',
  'components.layout.recentlyViewed.long': 'Continue where you left off',
  'components.layout.recentlyViewed.short': 'Recently viewed',
  'components.art.rent_for': 'Rent for',
  'components.art.sold': 'Sold',
  'components.art.sort': 'sort',
  'components.art.about_author': 'About {name}',
  'components.art.by': 'By',
  'components.art.includesOption': 'Includes',
  'components.art.addOption': 'Add',
  'components.art.size_tooltip_heading': 'Artwork size',
  'components.art.size_tooltip_description':
    'Artwork physical size measured as height by width by depth. Artwork purchased with a frame will usually be 5cm (2.5in) longer in each dimension.',
  'components.art.artwork_by_artist': '{title} by {artist}',
  'components.art.addFrame': 'Add a Frame',
  'components.art.framed': 'Framed',
  'components.art.view_full_details': 'View full Details',
  'components.art.add_to_basket': 'Add to Basket',
  'components.art.rent': 'Rent artwork',
  'components.art.rent_monthly': 'Rent from {rentPrice}/month',
  'components.art.edition_of': 'Edition of {editionSize}',
  'components.art.edition_of_short': 'of {editionSize}',
  'components.art.instalments.infoText': 'as low as {priceInInstalments}/month',
  'components.art.instalments.hintTitle': 'Pay in Instalments',
  'components.art.instalments.hintDescription':
    'Pay for your artwork in monthly interest free instalments from as little as {priceInInstalments}/month.',
  'components.art.instalments.hintLink': 'Learn More.',
  'components.art.options.insuranceHintTitle': 'Damage Waiver',
  'components.art.options.insuranceHintText':
    "For a small monthly fee, we'll reduce the amount you are liable to pay in the event of accidental damage or theft.",
  'components.art.options.rentHelpTitle': 'How does renting art work?',
  'components.art.options.frame.black_frame': 'Black Frame',
  'components.art.options.frame.walnut_frame': 'Walnut Frame',
  'components.art.options.frame.white_frame': 'White Frame',
  'components.art.full_details': 'View full Details',
  'components.art.noFrame': 'No Frame',
  'components.art.unframed': 'Unframed',
  'components.art.shipsFramed': 'Ships framed',
  'components.art.shipsFramedTooltipHeading': 'Artwork ships framed',
  'components.art.shipsFramedTooltipDescription':
    'This artwork is sold framed. For additional information on the frame please see the artwork detail information.',
  'components.art.shipsReadyToHang': 'Ships ready to hang',
  'components.art.shipsReadyToHangTooltipHeading': 'Artwork ready to hang',
  'components.art.shipsReadyToHangTooltipDescription':
    'This artwork is sold ready to hang. For additional information please see the artwork detail information.',
  'components.art.shipsFramedAndHang': 'Framed and ready to hang',
  'components.art.sold_out': 'Sorry, this artwork is sold out',
  'components.art.is_rented': 'Sorry, this artwork is rented',
  'components.art.is_enquire': 'Artwork not on sale, please enquire for availability',
  'components.art.enquire_work': 'Enquire about this work',
  'components.art.commissionArtist': 'Commission this artist',
  'components.art.makeOffer': 'Make an Offer',
  'components.art.unavailable': 'This artwork is unavailable.',
  'components.art.unavailable_to_buy':
    'If you are interested in acquiring this work, please contact us.',
  'components.art.unavailable_in_store':
    'This artwork is currently unavailable for purchase in this currency. You can purchase this artwork in {availableCurrencies} {totalStores, plural, one {currency} other {currencies}} by changing you preference at the top of the page.',
  'components.art.details.type': 'Type',
  'components.art.details.medium': 'Medium',
  'components.art.details.style': 'Style',
  'components.art.details.subject': 'Subject',
  'components.art.details.year': 'Year',
  'components.art.details.editionSize': 'Edition',
  'components.art.details.size': 'Size',
  'components.art.details.readyToHang': 'Ready to hang',
  'components.art.details.framed': 'Frame',
  'components.art.details.signed': 'Signed',
  'components.art.details.materials': 'Materials',
  'components.art.details.shipping': 'Shipping',
  'components.art.details.viewInRoom': 'View In A Room',
  'components.art.details.viewInRoomTruncated': 'In Room',
  'components.art.art_types.original_art': 'Original Art',
  'components.art.art_types.limited_edition': 'Limited Edition',
  'components.art.urgentMessages.original.heading': 'Original artwork',
  'components.art.urgentMessages.original.content': 'Only 1 available. Get it before it’s gone',
  'components.art.urgentMessages.edition.heading': 'Make it yours',
  'components.art.urgentMessages.edition.content': 'Limited quantities available',
  'components.art.urgentMessages.editionLimited.heading': 'Don’t miss out',
  'components.art.urgentMessages.editionLimited.content': 'Only {stock} left of this edition',
  'components.art.urgentMessages.cart.originalSingle.heading': 'Get It Before It’s Gone:',
  'components.art.urgentMessages.cart.originalSingle.content':
    'Your basket includes original artwork with only 1 available.',
  'components.art.urgentMessages.cart.originalMultiple.heading': 'Get It Before It’s Gone:',
  'components.art.urgentMessages.cart.originalMultiple.content':
    "Your basket includes original artwork. There's only 1 of each available",
  'components.art.urgentMessages.cart.editionLimited.heading': 'Don’t Miss Out:',
  'components.art.urgentMessages.cart.editionLimited.content':
    'Your basket includes editions with very limited stock.',
  'components.art.urgentMessages.cart.editionMultiple.heading': 'Don’t Miss Out:',
  'components.art.urgentMessages.cart.editionMultiple.content':
    "The artwork in your basket isn't yours until you complete your purchase.",
  'components.art.product_info': 'Artwork details',
  'components.art.description': 'About the artwork',
  'components.art.need_to_know': 'Need to know',
  'components.art.additional_info': 'Additional Information',
  'components.art.related_content': 'Related Content',
  'components.art.shipsFromCountry': 'Ships from {country}',
  'components.art.shipsFromShort': 'Ships from {country} by {date}',
  'components.art.shipsFromLong': 'Ships from {country} on or before {date}.',
  'components.art.curatorAvatarHeading': 'The insider {name} says:',
  'components.art.curatorHeading': 'Our insider says:',
  'components.art.viewProfile': 'View Profile',
  'components.art.need_help.title': 'Need Some Help?',
  'components.art.need_help.text':
    'If you would like to find out more about this artwork, talk to one of our curators and get expert advice for free.',
  'components.art.need_help.button': 'Get in touch now',
  'components.art.requestMoreImages.title': 'Request More Images',
  'components.art.requestMoreImages.titleTruncated': 'More Images',
  'components.art.requestMoreImages.message':
    'I would like to request additional images for this artwork',
  'components.art.arrangeViewing.title': 'Arrange A Viewing',
  'components.art.arrangeViewing.titleTruncated': 'Viewing',
  'components.art.arrangeViewing.message': 'I would like to arrange a viewing of this artwork',
  'components.art.more_artwork_by': 'More Works by {name}',
  'components.art.view_all_by': "View all {name}'s artworks",
  'components.art.viewAllWorks': 'View All Works',
  'components.art.similarArtworks': 'Visually Similar Artworks',
  'components.art.similarArtworksTruncated': 'Similar Art',
  'components.art.similarCategoryLinks': 'Other artworks in similar styles',
  'components.art.similarArtistLinks': 'Other artists in the same medium',
  'components.art.recentlyViewedArtworks': 'Recently viewed artworks',
  'components.art.delivery': 'Delivery',
  'components.art.commissionLabel': 'Commission a similar artwork',
  'components.art.commissionLabelAction': 'Learn How',
  'components.art.returns': 'Free Returns',
  'components.art.returnsLabel': 'Free & easy 14 days returns',
  'components.art.payInInstalments': 'Pay in instalments',
  'components.art.worldwideShipping': 'Worldwide safe shipping',
  'components.art.kazoartProLeasing': '<strong>Professionals:</strong> lease this work',
  'components.art.findSimilarArtworks': 'Find Visually Similar Artworks',
  'components.art.gridNoArtworksDefault': 'No artworks found.',
  'components.art.gridNoArtworks':
    '<p>No artworks found. Please change your search or filter criteria.</p><p><strong>SEARCHING FOR ARTISTS?</strong> <a>Click Here.</a></p>',
  'components.art.gridNoArtworksLinkTitle': 'Click here to search for artists.',
  'components.art.trustedBy': 'Trusted by leading brands including {link}',
  'components.art.gridView': 'Grid View',
  'components.art.detailsView': 'Gallery View',
  'components.art.openDetailsView': 'Open in gallery view',
  'components.art.viewInRoomBkgAlt': 'View in a room interior',
  'components.artist.artist': 'Artist',
  'components.artist.profile': 'About',
  'components.artist.cv': 'CV',
  'components.artist.cvHeader': 'C.V.',
  'components.artist.artworks': '{name} Artworks',
  'components.artist.studio': 'Studio Diary',
  'components.artist.artistStudio': '{name} Studio Diary',
  'components.artist.artistNews': '{name} News & Exhibitions',
  'components.artist.news': 'News & Exhibitions',
  'components.artist.featuredIn': 'Featured in',
  'components.artist.recentActivity': 'Recent activity',
  'components.artist.commissionTitle': 'Commission an artwork by {name}',
  'components.artist.commissionSubtitle':
    'We can arrange and oversee the creation of a new work, made specifically for you.',
  'components.artist.findArtTitle': 'Not what you were looking for?',
  'components.artist.findArtSubtitle':
    'Find the perfect artwork using our filters or {link} to help you discover it.',
  'components.artist.findArtSubtitleLink': 'talk with one of our advisors',
  'components.artist.findArtAction': 'Browse for art',
  'components.artist.events': 'On View',
  'components.artist.viewCv': 'View CV',
  'components.artist.visitStudio': 'Visit the studio',
  'components.artist.bornIn': '{year} in {location}',
  'components.artist.bornInLabel': 'Born',
  'components.artist.livesLabel': 'Lives and Works',
  'components.artist.education': 'Education',
  'components.artist.awards': 'Awards',
  'components.artist.collections': 'Collections',
  'components.artist.soloExhibitions': 'Selected solo exhibitions',
  'components.artist.groupExhibitions': 'Selected group exhibitions',
  'components.artist.bibliography': 'Bibliography',
  'components.artist.expandButtonLabel': 'full list',
  'components.artist.printCV': 'Print Full CV',
  'components.artist.noStudioFeed': 'There are currently no studio diary posts for this artist.',
  'components.artist.noNews': 'There are currently no news or exhibitions for this artist.',
  'components.artist.gridNoArtistsDefault': 'No artists found.',
  'components.artist.gridNoArtists':
    '<p>No artists found. Please change your search or filter criteria.</p><p><strong>SEARCHING FOR ARTWORKS?</strong> <a>Click Here.</a></p>',
  'components.artist.gridNoArtistsLinkTitle': 'Click here to search for artworks.',
  'components.artist.unavailable': 'This artist is unavailable.',
  'components.artist.careerStage.established': 'Blue Chip Artist',
  'components.artist.careerStage.emerging': 'Emerging Artist',
  'components.artist.careerStage.new': 'New & Noteworthy Artist',
  'components.event.type.soloShow': 'solo show',
  'components.event.type.artFair': 'art fair',
  'components.event.type.talk': 'talk',
  'components.event.type.groupShow': 'group show',
  'components.event.type.other': 'other',
  'components.event.listTitle': 'Events',
  'components.event.rsvpBtnLabel.line1': 'RS',
  'components.event.rsvpBtnLabel.line2': 'VP',
  'components.event.rsvpStatus.0': 'No',
  'components.event.rsvpStatus.1': 'Yes',
  'components.event.rsvpStatus.2': 'Maybe',
  'components.event.headings.description': 'Description',
  'components.event.headings.dateAndTime': 'Date & time',
  'components.event.headings.address': 'Address',
  'components.event.headings.attendees': 'Attendees',
  'components.event.headings.share': 'Share this event',
  'components.event.addCalendar': 'Add to calendar',
  'components.event.visitSite': 'Visit site',
  'components.filters.showMore': 'Show more filters',
  'components.filters.showLess': 'Show fewer filters',
  'components.filters.clearFilters': 'Clear',
  'components.filters.clearAll': 'Clear all',
  'components.filters.selectedCount': 'selected',
  'components.filters.rangeUnits': '{min} - {max} {units}',
  'components.filters.rangePrice': '{min} - {max} {currency}',
  'components.filters.price.label': 'Price',
  'components.filters.size.label': 'Size',
  'components.filters.orientation.label': 'Orientation',
  'components.filters.color.label': 'Color',
  'components.filters.color.defaultText': 'All Colors',
  'components.filters.color.multiple': 'Selected Colors',
  'components.filters.color.title': 'Select Colors',
  'components.filters.color.noOptions': 'No colors available',
  'components.filters.medium.label': 'Medium',
  'components.filters.medium.defaultText': 'All Mediums',
  'components.filters.medium.multiple': 'Selected Mediums',
  'components.filters.medium.title': 'Select Mediums',
  'components.filters.medium.noOptions': 'No mediums available',
  'components.filters.style.label': 'Style',
  'components.filters.style.defaultText': 'All Styles',
  'components.filters.style.multiple': 'Selected Styles',
  'components.filters.style.title': 'Select Styles',
  'components.filters.style.noOptions': 'No styles available',
  'components.filters.subject.label': 'Subject',
  'components.filters.subject.defaultText': 'All Subjects',
  'components.filters.subject.multiple': 'Selected Subjects',
  'components.filters.subject.title': 'Select Subjects',
  'components.filters.subject.noOptions': 'No subjects available',
  'components.filters.gender.label': 'Gender',
  'components.filters.initial.label': 'Surname',
  'components.filters.initial.defaultText': 'All names',
  'components.filters.initial.multiple': 'Selected names',
  'components.filters.initial.title': 'Select names',
  'components.filters.initial.noOptions': 'No names available',
  'components.filters.ships.label': 'Ships from',
  'components.filters.ships.defaultText': 'All countries',
  'components.filters.ships.multiple': 'Selected countries',
  'components.filters.ships.title': 'Select countries',
  'components.filters.ships.noOptions': 'No countries available',
  'components.filters.stage.label': 'Career stage',
  'components.filters.stage.defaultText': 'All career stages',
  'components.filters.stage.multiple': 'Selected career stages',
  'components.filters.stage.title': 'Select career stages',
  'components.filters.stage.noOptions': 'No career stages available',
  'components.filters.region.label': 'Region',
  'components.filters.region.defaultText': 'All regions',
  'components.filters.region.multiple': 'Selected regions',
  'components.filters.region.title': 'Select regions',
  'components.filters.region.noOptions': 'No regions available',
  'components.filters.born.label': 'Nationality',
  'components.filters.born.defaultText': 'All nationalities',
  'components.filters.born.multiple': 'Selected nationalities',
  'components.filters.born.title': 'Select nationalities',
  'components.filters.born.noOptions': 'No nationalities available',
  'components.filters.height.label': 'Height',
  'components.filters.width.label': 'Width',
  'components.filters.showFilters': 'Show filters',
  'components.filters.hideFilters': 'Hide filters',
  'components.filters.breadcrumbs.artist': '{dimension1} {dimension2}',
  'components.filters.breadcrumbs.art': '{dimension1} {dimension2}',
  'components.filters.breadcrumbs.collection': '{dimension1} {dimension2}',
  'components.filters.parentChildDimensions': '{dimension2} {dimension1}',
  'components.filters.mediumStyleLabel': '{style} {medium}',
  'components.cms.modules.homeimage.h3': 'Contemporary',
  'components.cms.modules.homeimage.h2': 'Art',
  'components.cms.modules.homeimage.h4': 'Hand Picked by Experts',
  'components.cms.modules.homeimage.browse': 'BROWSE OUR',
  'components.cms.modules.homeimage.curated': 'CURATED',
  'components.cms.modules.homeimage.selection': 'SELECTION OF ART',
  'components.cms.modules.homeimage.browsing': 'Just browsing?',
  'components.cms.modules.homeimage.curators': 'Let our curators guide you',
  'components.cms.modules.homeimage.by': 'by',
  'components.cms.modules.legal.effectiveDate': 'Effective',
  'components.cms.modules.legal.version': 'Version',
  'components.cms.modules.reviews.readAll': 'Read all our reviews',
  'components.share.facebook': 'Facebook',
  'components.share.twitter': 'Twitter',
  'components.share.email': 'E-mail',
  'components.share.whatsapp': 'Whatsapp',
  'components.share.pinterest': 'Pinterest',
  'components.share.options.facebook': 'Facebook',
  'components.share.options.twitter': 'Twitter',
  'components.share.options.email': 'E-mail',
  'components.share.options.whatsapp': 'Whatsapp',
  'components.share.options.pinterest': 'Pinterest',
  'components.share.options.messenger': 'Messenger',
  'components.share.options.link': 'Link',
  'components.share.messages.article.facebook': 'Check out {title} on @riseart #RiseArt ',
  'components.share.messages.article.twitter': 'Check out {title} on @riseart #RiseArt ',
  'components.share.messages.article.pinterest':
    '\n  Check out {title} on @riseart #RiseArt\n\n  {link}\n  ',
  'components.share.messages.article.emailSubject': 'Check out {title} on @riseart ',
  'components.share.messages.article.email': '\n  Check out {title} on @riseart #RiseArt\n\n',
  'components.share.messages.article.whatsapp': 'Check out {title} on @riseart {link} #RiseArt ',
  'components.share.messages.article.messenger': 'Check out {title} on @riseart {link} #RiseArt ',
  'components.share.messages.article.link': 'Check out {title} on @riseart {link} #RiseArt ',
  'components.share.messages.guide.facebook': 'Check out {title} on @riseart #RiseArt ',
  'components.share.messages.guide.twitter': 'Check out {title} on @riseart #RiseArt ',
  'components.share.messages.guide.pinterest':
    '\n  Check out {title} on @riseart #RiseArt\n\n  {link}\n  ',
  'components.share.messages.guide.emailSubject': 'Check out {title} on @riseart ',
  'components.share.messages.guide.email': '\n  Check out {title} on @riseart #RiseArt\n\n',
  'components.share.messages.guide.whatsapp': 'Check out {title} on @riseart {link} #RiseArt ',
  'components.share.messages.guide.messenger': 'Check out {title} on @riseart {link} #RiseArt ',
  'components.share.messages.guide.link': 'Check out {title} on @riseart {link} #RiseArt ',
  'components.share.messages.artwork_detail.facebook': 'Check out {name} by {artist} on Rise Art ',
  'components.share.messages.artwork_detail.twitter': 'Check out {name} by {artist} on Rise Art ',
  'components.share.messages.artwork_detail.pinterest':
    '\nCheck out {name} by {artist} on Rise Art\n\n{url}\n',
  'components.share.messages.artwork_detail.emailSubject':
    'Check out {name} by {artist} on Rise Art',
  'components.share.messages.artwork_detail.email':
    '\nCheck out this artwork on Rise Art and let me know what you think!\n\n{url}',
  'components.share.messages.artwork_detail.whatsapp': 'Check out {name} by {artist} on Rise Art ',
  'components.share.messages.artwork_detail.messenger': 'Check out {name} by {artist} on Rise Art ',
  'components.share.messages.artwork_detail.link': 'Check out {name} by {artist} on Rise Art ',
  'components.share.messages.collection_detail.facebook':
    'Check out this collection from Rise Art: {name} ',
  'components.share.messages.collection_detail.twitter':
    'I am admiring the {name} collection on Rise Art #{name} ',
  'components.share.messages.collection_detail.pinterest': '\n{name} | Rise Art\n\n{url}\n',
  'components.share.messages.collection_detail.emailSubject':
    "You'll love this collection from Rise Art: {name}",
  'components.share.messages.collection_detail.email':
    '\nHey!\n\nI am admiring the {name} collection on Rise Art. Check it out and let me know what you think.\n\nLink: {url}',
  'components.share.messages.collection_detail.whatsapp':
    'Check out this collection from Rise Art: {name}',
  'components.share.messages.collection_detail.messenger':
    'Check out this collection from Rise Art: {name}',
  'components.share.messages.collection_detail.link':
    'Check out this collection from Rise Art: {name}',
  'components.share.messages.quiz_personality.facebook':
    'My Art personality is {name}. Check out what this means about my Art Style & Taste with the Rise Art Personality Quiz',
  'components.share.messages.quiz_personality.twitter':
    'My Art personality is {name}. Check out what this means about my Art Style & Taste with the Rise Art Personality Quiz #DiscoverYourStyle',
  'components.share.messages.quiz_personality.pinterest':
    'My Art personality is {name}. Check out what this means about my Art Style & Taste with the Rise Art Personality Quiz',
  'components.share.messages.quiz_personality.emailSubject': 'My Art personality is {name}',
  'components.share.messages.quiz_personality.email':
    'Hey :)\n\nI just did the Rise Art Personality Quiz and apparently I am the {name}. Check out what this means about my Art Style & Taste. Let me know if you do the quiz and let’s see if we match!\n\n\nLink: {link}',
  'components.share.messages.quiz_personality.whatsapp':
    'Hey :) I just did the Rise Art Personality Quiz and apparently I am the {name}. Check out what this means about my Art Style & Taste. Let me know if you do the quiz and let’s see if we match !',
  'components.share.messages.quiz_personality.messenger': '',
  'components.share.messages.quiz_personality.link': '',
  'components.share.messages.artistProfile.facebook': 'Check out {artist} on Rise Art ',
  'components.share.messages.artistProfile.twitter': 'Check out {artist} on Rise Art ',
  'components.share.messages.artistProfile.pinterest':
    '\nCheck out {artist} on Rise Art\n\n{url}\n',
  'components.share.messages.artistProfile.emailSubject': 'Check out {artist}',
  'components.share.messages.artistProfile.email': '\nCheck out {artist} on Rise Art\n\n{url}',
  'components.share.messages.artistProfile.whatsapp': 'Check out {artist} on Rise Art ',
  'components.share.messages.artistProfile.messenger': 'Check out {artist} on Rise Art ',
  'components.share.messages.artistProfile.link': 'Check out {artist} on Rise Art ',
  'components.share.messages.event.facebook':
    'Check out the event {name} on Rise Art @riseart #RiseArt ',
  'components.share.messages.event.twitter':
    'Check out the event {name} on Rise Art @riseart #RiseArt ',
  'components.share.messages.event.pinterest':
    '\nCheck out the event {name} on Rise Art @riseart #RiseArt\n\n{link}\n',
  'components.share.messages.event.emailSubject':
    'Check out the event {name} on Rise Art @riseart #RiseArt ',
  'components.share.messages.event.email':
    '\nCheck out the event {name} on Rise Art @riseart #RiseArt\n\n',
  'components.share.messages.event.whatsapp':
    'Check out the event {name} on Rise Art {link} @riseart #RiseArt ',
  'components.share.messages.event.messenger':
    'Check out the event {name} on Rise Art {link} @riseart #RiseArt ',
  'components.share.messages.event.link':
    'Check out the event {name} on Rise Art {link} @riseart #RiseArt ',
  'components.quiz.start_test': 'Start the test',
  'components.quiz.landing_page_arrow': 'Want to read more about the science behind it?',
  'components.quiz.back_button': 'back',
  'components.quiz.next_button': 'next',
  'components.quiz.ready_button': "I'm ready",
  'components.quiz.lets_go_button': "Let's go",
  'components.quiz.swipe_left': 'Swipe LEFT if you DON’T LIKE the artwork',
  'components.quiz.swipe_right': 'Swipe RIGHT if you LIKE the artwork',
  'components.quiz.swipe_btn_text': 'Got it',
  'components.quiz.share_results_title': 'Share your results and find out how your friends compare',
  'components.quiz.share_art_personality': 'Share your art personality',
  'components.quiz.share_art_personality_hint': 'and find out how your friends compare',
  'components.quiz.share_results': 'Share your results',
  'components.quiz.result_banner_heading': 'You are',
  'components.quiz.result_score_averages': 'General population score:',
  'components.quiz.result_compared_title': 'This is you, compared to others',
  'components.quiz.result_collection_title': 'Collections for {name}',
  'components.quiz.result_artists_title': 'Artists for {name}',
  'components.quiz.result_recommended_title': 'Art for {name}',
  'components.quiz.result_recommended_hint': 'based on your scores',
  'components.quiz.result_recommend_title':
    "Know someone else who'd like to take the quiz? Send it to them",
  'components.quiz.results_chart_score_label': 'You',
  'components.quiz.discover_your_personality': 'Discover your art personality',
  'components.quiz.discover_visual_preferences':
    'Find out what your visual preferences say about you',
  'components.quiz.take_test': 'Take the test',
  'components.quiz.art_personality': 'ART PERSONALITY',
  'components.quiz.other_art_personalities': 'Other art personalities',
  'components.quiz.profile_compared_to_others': 'These are {name} trait scores, compared to others',
  'components.quiz.result_trait_arousing':
    'Diverse use of colours; red; intense scenes; sharp, irregular features; cubism.',
  'components.quiz.result_trait_minimalist':
    'Simple; structured; recurring patterns; recurring themes; unified features; geometric; simple.',
  'components.quiz.result_trait_figurative':
    'Landscapes; portraits; representational; not reflective; contextual information not required; coherent narrative; traditional; impressionism.',
  'components.quiz.result_trait_shocking':
    'Nonlinear, possibly violent, morbid, sexual imagery; hostile',
  'components.quiz.auth_page_text.main_text': 'Congratulations!',
  'components.quiz.auth_page_text.hint_text':
    'Please provide your details to discover your Art Personality.',
  'components.quiz.auth_page_text.bottom_text':
    'We are unable to save or deliver your results if you leave this page without providing your details.',
  'components.quiz.auth_page_text.continue_with': 'Continue with',
  'components.quiz.auth_page_text.send_email_results':
    'or sign up to receive your results in your inbox',
  'components.quiz.auth_page_text.subscribe_button': 'Get my results',
  'components.quiz.validation_messages.default_message':
    'The information you entered is invalid, please correct it',
  'components.quiz.errors.questions_not_loaded':
    'We have been unable to load the page from the server. Please check your internet connection and refresh this page',
  'components.quiz.errors.result_page_not_loaded':
    'We have been unable to load the quiz rezults from the server. Please check your internet connection and refresh this page',
  'components.quiz.errors.submit_answers':
    'We have been unable to submit your answers to the server.',
  'components.collections.curator_text': 'Curated by {name} {curatorText}',
  'components.collections.curator_text_short': 'Curated by {name}',
  'components.collections.errors.banner_data_not_loaded':
    'We have been unable to load the page from the server. Please check your internet connection and refresh this page.',
  'components.collections.errors.collection_list_not_loaded':
    'We have been enable to load the collections list from the server. Please check your internet connection and refresh this page.',
  'components.collections.list_title': 'Our Curated Collections',
  'components.auth.effective_date': 'Effective: {date}',
  'components.auth.facebook_signin': 'Sign in with Facebook',
  'components.auth.facebook_signup': 'Sign up with Facebook',
  'components.auth.google_signin': 'Sign in with Google',
  'components.auth.google_signup': 'Sign up with Google',
  'components.auth.signin': 'Sign in to Rise Art',
  'components.auth.signup': 'Sign up with your Rise Art account',
  'components.auth.signup_social': 'Sign up to Rise Art',
  'components.auth.signupTab': 'New to Rise Art?',
  'components.auth.loginTab': 'Already Registered?',
  'components.media.noVIdeoSupport': 'Your browser does not support the video tag.',
  'components.pagination.page': 'Page',
  'components.pagination.next': 'Next page',
  'components.pagination.previous': 'Previous page',
  'components.pagination.infiniteCounter':
    '{count, plural, =0 {no pieces found} other {showing {countFormatted} pieces}}',
  'components.pagination.sliderCounter':
    '{total, plural, =0 {no pieces found} other {showing {from}-{to} of {totalFormatted}}}',
  'components.cart.myBasket': 'My Basket',
  'components.cart.yourBasket': 'Your Basket',
  'components.cart.yourRental': 'Your Rental',
  'components.cart.printOrder': 'Print order',
  'components.cart.freeShipping': 'Free',
  'components.cart.total': 'Total',
  'components.cart.subtotal': 'Subtotal',
  'components.cart.shipping': 'Shipping',
  'components.cart.qty': 'Qty',
  'components.cart.secure': 'Secure',
  'components.cart.sslEncryption': 'SSL Encryption',
  'components.cart.discountCode': 'Discount',
  'components.cart.credit': 'Credit',
  'components.cart.giftCard': 'Gift Card',
  'components.cart.continueToCheckout': 'Continue to checkout',
  'components.cart.paymentOptions': 'Payment Options',
  'components.cart.safeCheckout': 'Safe & Secure Checkout',
  'components.cart.rentalCreditNote':
    'To validate your payment details and setup your rental account, we will need to charge a minimal amount against your card.',
  'components.cart.instalmentsHint': 'Want to spread the cost over 10 months with 0% interest?',
  'components.cart.customDutiesHint': 'Customs duties may apply,',
  'components.cart.checkHere': 'check here',
  'components.cart.countItems': '{count, plural, =0 {no items} one {# item} other {# items}}',
  'components.cart.rentalDayDuration': '{days, plural, one {# day rental} other {# days rental}}',
  'components.cart.rentalForthnightDuration':
    '{fortnights, plural, one {# fortnight rental} other {# fortnights rental}}',
  'components.cart.rentalMonthDuration':
    '{months, plural, one {# month rental} other {# months rental}}',
  'components.cart.rentalYearDuration':
    '{years, plural, one {# year rental} other {# years rental}}',
  'components.cart.offer': 'Applied offer',
  'components.cart.viewBasket': 'View basket',
  'components.cart.checkout': 'Checkout',
  'components.cart.usingCouponCode': 'Using coupon code {couponCode}',
  'components.cart.customCharges': 'Customs duties may apply, check {link}',
  'components.cart.customChargesTitle': 'Shipping and Customs Charges',
  'components.cart.sidebarBasketEmptyText': 'You have no items in your shopping basket.',
  'components.cart.basketEmptyTitle': 'Your shopping basket is empty',
  'components.cart.basketEmptyText':
    'You have no items in your shopping basket. You can add items to the shopping basket from your wishlist below or you can click {link} to continue shopping.',
  'components.cart.rentalEmptyTitle': 'No rental selected',
  'components.cart.rentalEmptyText':
    'There is currently no rental selected. You can rent any artwork from its detail page. Click {link} to continue shopping.',
  'components.cart.yourCredit': 'Your credit',
  'components.cart.gotGiftCart': 'Got a gift card?',
  'components.cart.useDiscount': 'Use your discount coupon',
  'components.cart.noVouchers': 'There are no vouchers linked to your account.',
  'components.cart.signInCredit1': 'Please ',
  'components.cart.signInCredit2': 'sign up',
  'components.cart.signInCredit3': ' to use your credit.',
  'components.cart.redeemCredit1': 'Please redeem it ',
  'components.cart.redeemCredit2': 'here',
  'components.cart.redeemCredit3':
    ' to get your credit in your account ready to spend at checkout.',
  'components.cart.signInRedeem1': 'Please ',
  'components.cart.signInRedeem2': 'sign in',
  'components.cart.signInRedeem3':
    ' to redeem your credit card and get your credits in your account ready to spend at checkout.',
  'components.cart.useCredit': 'Use my credit for this order',
  'components.cart.redeemGiftCard': 'Redeem your gift card',
  'components.cart.redeemGiftDesc': 'Get your credits in your account ready to spend at checkout',
  'components.cart.giftCardCode': 'Voucher Code',
  'components.cart.giftCardSecurity': 'Security Code',
  'components.cart.giftCardSecurityDesc':
    'If your voucher includes a security code, please provide it to redeem your credit.',
  'components.cart.shipsReadyToHangTooltipHeading': 'Rental ready to hang',
  'components.cart.shipsReadyToHangTooltipDescription': 'All rentals are shipped ready to hang.',
  'components.cart.confirmationHeading': 'Thank you for your order',
  'components.cart.confirmationHeadingShort': 'Thank you',
  'components.cart.confirmationDetailsHeading': 'Order details #{orderId}',
  'components.cart.confirmationDetailsHeadingShort': 'Order #{orderId}',
  'components.cart.confirmationDetailsDescription':
    'A confirmation email will be sent to you shortly on {email}',
  'components.cart.confirmationSubscribeHeading': 'Get our tips by email',
  'components.cart.confirmationSubscribeButton': "Yes, I'm in",
  'components.cart.confirmationSubscribeDescription':
    "We'll give you lots of ideas about choosing art for your home, show you what interior designers are picking out, and give you a peek at what's new each week.",
  'components.cart.confirmationBlogHeading': 'Fresh from our blog',
  'components.cart.confirmationReviewHint':
    'Please review your order and contact us if you have any question or issue. Any saved cards or addresses will be available for your next purchase and can be edited on your ',
  'components.cart.confirmationReviewReturnPolicy':
    'For information about returns, please read our ',
  'components.cart.confirmationReviewActionBtn': 'Continue Shopping',
  'components.cart.creditApplied': 'Credit was applied to the shopping basket',
  'components.cart.creditRemoved': 'Credit was removed from the shopping basket',
  'components.checkout.steps.info': 'My information',
  'components.checkout.steps.infoShort': 'Contact',
  'components.checkout.steps.shipping': 'Delivery address',
  'components.checkout.steps.shippingShort': 'Delivery',
  'components.checkout.steps.addShipping': 'New delivery address',
  'components.checkout.steps.editShipping': 'Edit delivery address',
  'components.checkout.steps.billing': 'Payment',
  'components.checkout.steps.billingAddress': 'Billing address',
  'components.checkout.cards.visa': 'Visa',
  'components.checkout.cards.master': 'Mastercard',
  'components.checkout.cards.maestro': 'Maestro',
  'components.checkout.cards.amex': 'American Express',
  'components.checkout.cards.diners': 'Diners Club',
  'components.checkout.cards.discover': 'Discover',
  'components.checkout.cards.jcb': 'JCB',
  'components.checkout.cards.unionpay': 'UnionPay',
  'components.checkout.cards.unknown': 'Unknown Card',
  'components.checkout.cards.default': 'Card',
  'components.checkout.orderDate': 'Order Date',
  'components.checkout.yourInformation': 'Your Information',
  'components.checkout.paymentMethod': 'Payment method',
  'components.checkout.continueToDelivery': 'Continue to delivery',
  'components.checkout.deliverToAddress': 'Deliver to this address',
  'components.checkout.continueToReview': 'Continue to review order',
  'components.checkout.deliveryHint':
    'Your artwork will require signature upon delivery, please make sure you provide and address where someone will be availabe to receive it.',
  'components.checkout.paymentHint': 'All transactions are secure and encrypted.',
  'components.checkout.reviewOrder': 'Review your order',
  'components.checkout.reviewOrderHint':
    'You will not be charged at this point. You will be able to review your order before you submit it.',
  'components.checkout.placeOrder': 'Place your order',
  'components.checkout.placeOrderHint':
    'By placing your order you agree to our {terms}, {policy} and {returnPolicy}. You also consent to some of your data being stored by Rise Art, which may be used to make future shopping experience better for you.',
  'components.checkout.checkoutButtonExternal': 'Proceed to {paymentType}',
  'components.checkout.creditHeading': 'Gift Cards And Credits',
  'components.checkout.creditLabel':
    'Use my credit for this order. Your total available credit is {credit}',
  'components.checkout.creditDescription1': 'Got a Gift Card? Add it to your gift-voucher balance ',
  'components.checkout.creditDescription2': ' before you can redeem it at checkout.',
  'components.checkout.cardValidThru': 'Valid thru',
  'components.checkout.addPayment': 'Change payment',
  'components.checkout.usePayment': 'Select payment',
  'components.checkout.useAddress': 'Use this address',
  'components.checkout.paymentDetails': 'Payment details',
  'components.checkout.yourSavedCards': 'Your saved cards',
  'components.checkout.usedCredits': 'Rise Art Credits',
  'components.checkout.useCredits': 'Use <strong>my credits</strong> for this order',
  'components.checkout.useCard': 'Use the following <strong>card</strong> for this order:',
  'components.checkout.useCardAndCredits':
    'Use <strong>my credits</strong> and the following <strong>card</strong> for this order:',
  'components.checkout.usePaymentType': 'Use <strong>{paymentType}</strong>',
  'components.checkout.usePaymentTypeAndCredits':
    'Use <strong>my credits</strong> and <strong>{paymentType}</strong> for this order',
  'components.checkout.securityCodeHintTitle': 'Security code',
  'components.checkout.securityCodeHintDescription':
    'For Visa and Mastercard, this is the last three digits on the back of your card found in the signature strip',
  'components.checkout.savePayment': 'Save card details and billing address for next time',
  'components.checkout.saveShippingAddress': 'Save shipping address for next time',
  'components.checkout.declinedPayment':
    'Payment was declined. If you still have problems with payments, please contact us.',
  'components.checkout.notLoadedStripe':
    'Please check your network connection and ty again. If you still have problems with payments, please contact us.',
  'components.checkout.footerMenu.payment': 'Secure Payment',
  'components.checkout.footerMenu.return': 'Return Policy',
  'components.checkout.footerMenu.returnTruncated': 'Return',
  'components.checkout.footerMenu.contact': 'Contact Us',
  'components.checkout.footerMenu.contactTruncated': 'Contacts',
  'components.checkout.order.state.new': 'New',
  'components.checkout.order.state.pending_payment': 'Pending payment',
  'components.checkout.order.state.processing': 'Processing',
  'components.checkout.order.state.complete': 'Complete',
  'components.checkout.order.state.closed': 'Closed',
  'components.checkout.order.state.canceled': 'Canceled',
  'components.checkout.order.state.holded': 'Holded',
  'components.checkout.order.state.payment_review': 'Payment review',
  'components.checkout.paymentDescription': '{paymentType}',
  'components.checkout.paymentDescriptionAccount': '{paymentType} account {account}',
  'components.checkout.paymentType.card': 'Credit or Debit Card',
  'components.checkout.paymentType.paypal': 'PayPal',
  'components.checkout.paymentType.google_pay': 'Google Pay',
  'components.checkout.paymentType.apple_pay': 'Apple Pay',
  'components.checkout.paymentType.klarna': 'Klarna',
  'components.checkout.paymentType.sepa_debit': 'Sepa Debit',
  'components.checkout.paymentType.sofort': 'Sofort',
  'components.checkout.paymentType.giropay': 'Giropay',
  'components.checkout.paymentType.wechat_pay': 'WeChat Pay',
  'components.checkout.paymentType.stripe': 'Stripe Payments',
  'components.checkout.paymentCategory.pay_later': 'Pay later',
  'components.checkout.paymentCategory.pay_with_financing': 'Pay with financing',
  'components.checkout.paymentCategory.pay_now': 'Pay now',
  'components.checkout.paymentCategory.pay_in_installments': 'Pay in installments',
  'components.checkout.orderReviewMessage':
    'Your order is waiting payment confirmation. You will receive an order confirmation email once your payment has been completed. Should you have any questions, please contact us at <a>email</a> quoting your order number below.',
  'components.checkout.orderCancelMessage':
    'Payment for your order has been canceled or declined. Should you have any questions, please contact us at <a>email</a> quoting your order number below.',
  'components.checkout.paymentCancelMessage':
    'Payment for your order has been canceled or declined. Please review your payment information and try again.',
  'components.checkout.completePaymentInformation':
    'Please make sure you complete all of the payment information above to continue.',
  'components.countdown.days': 'days',
  'components.countdown.hours': 'hrs',
  'components.countdown.minutes': 'mins',
  'components.countdown.seconds': 'secs',
  'components.appMessages.blackFriday.title':
    'INDULGE IN LUXURY: Black Friday Savings of Up to 25%',
  'components.appMessages.blackFriday.titleMobile':
    'INDULGE IN LUXURY<br></br>Black Friday Savings of Up to 25%',
  'components.appMessages.blackFriday.titleCondensed': 'Black Friday Savings of Up to 25%',
  'components.appMessages.blackFriday.countdownLabelTruncated': 'Ends',
  'components.appMessages.blackFriday.art15':
    'spend {currencySign}500 to {currencySign}1500 <div>Get <strong>10% Off</strong> with <strong>ART10</strong></div>',
  'components.appMessages.blackFriday.art250':
    'spend {currencySign}1500+ <div>Get <strong>{currencySign}200 Off</strong> with <strong>ART200</strong></div>',
  'components.appMessages.blackFriday.art500':
    'spend {currencySign}3000+ <div>Get <strong>{currencySign}500 Off</strong> with <strong>ART500</strong></div>',
  'components.appMessages.blackFriday.art1000':
    'spend {currencySign}5000+ <div>Get <strong>{currencySign}1000 Off</strong> with <strong>ART1000</strong></div>',
  'components.appMessages.blackFriday.art8000':
    'spend {currencySign}7000+ <div>Get <strong>{currencySign}1750 Off</strong> with <strong>ART1750</strong></div>',
  'components.appMessages.artistSpotlight.title':
    'Artist Spotlight Series: <strong>ENJOY 15% OFF <a>selected artworks</a>.</strong> Use <strong>SPOTLIGHT15</strong> at checkout.',
  'components.appMessages.artistSpotlight.titleMobile': 'Artist Spotlight Series',
  'components.appMessages.artistSpotlight.subTitleMobile':
    '<strong>ENJOY 15% OFF <a>selected artworks</a>.</strong> Use <strong>SPOTLIGHT15</strong> at checkout.',
  'components.appMessages.artistSpotlight.titleCondensed': 'Artist Spotlight Series',
  'components.appMessages.artistSpotlight.titleCondensedMobile': 'Artist Spotlight Series',
  'components.appMessages.artistSpotlight.titleExtended':
    'Artist Spotlight Series: <strong>ENJOY 15% OFF <a>selected artworks</a>.</strong> Ends in ',
  'components.appMessages.artistSpotlight.titleExtendedMobile': 'Artist Spotlight Series',
  'components.appMessages.artistSpotlight.subTitleExtendedMobile':
    'ENJOY 15% OFF <strong><a>selected artworks</a>.</strong>',
  'components.appMessages.artistSpotlight.countdownLabelTruncated': 'ENDS ',
  'components.appMessages.artistSpotlight.codeExtended':
    'Use <strong>SPOTLIGHT15</strong> at checkout.',
  'components.appMessages.artistSpotlight.codeExtendedMobile':
    'Use <strong>SPOTLIGHT15</strong> at checkout. Offer ends 28/04 midnight.',
  'components.appMessages.artistSpotlight.code': 'Offer ends 28/04 midnight.',
  'components.appMessages.promotions.visualSearch.title':
    'Click {visualSimilarIcon} to discover similar artworks',
  'components.appMessages.promotions.visualSearch.text':
    'Use visual search to find art with a similar style',
  'components.appMessages.promotions.visualSearch.action': 'Try It Now',
  'components.appMessages.promotions.returns.title': 'Free and easy 14 days returns',
  'components.appMessages.promotions.returns.action': 'Get In Touch',
  'components.appMessages.promotions.advisors.title': 'Speak with our curators',
  'components.appMessages.promotions.advisors.action': 'Get In Touch',
  'components.appMessages.promotions.reviews.title': 'Trusted by collectors and leading brands',
  'components.appMessages.promotions.reviews.text': 'See our latest collaboration with Soho Home',
  'components.appMessages.promotions.reviews.action': 'Soho Home x Rise art',
  'components.appMessages.promotions.filters.title': 'Refine your search. find the perfect artwork',
  'components.appMessages.promotions.filters.text': 'Filter by size, medium, price and more',
  'components.appMessages.promotions.filters.action': 'Try It Now',
  'components.appMessages.promotions.saveWishlist.title': 'Save your wishlist',
  'components.appMessages.promotions.saveWishlist.text':
    'Your favourite art is only temporary saved. To keep them safe, create a Rise Art account.',
  'components.appMessages.promotions.saveWishlist.action': 'Create an Account',
  'components.blog.landingTitle': 'Editorial',
  'components.blog.curatorsPicks': "Curator's Picks",
  'components.blog.mostRead': 'Most Read',
  'components.blog.moreFrom': 'More From',
  'components.blog.relatedArtworks': 'Related Artworks',
  'components.blog.furtherReading': 'Further Reading',
  'components.blog.subscribe.title': 'Subscribe to our newsletter',
  'components.blog.subscribe.subtitle': 'Get early access to artist stories, new art and promos',
  'components.blog.subscribe.submitBtn': 'Sign Up',
  'components.guides.landingTitle': 'Art Guides',
  'components.guides.landingDescription':
    'Discover the latest art news, recent releases and events. Whether you’re wanting to keep up-to-date with art world news, looking to gain more of an insight into art history, or interested in learning about the key art movements on the scene, find all you need to know about contemporary art with the Rise Art blog.',
  'components.guides.furtherReading': 'Related Guides',
  'components.guides.readNext': 'Read This Next',
  'components.guides.subscribe.title': 'Stay in the loop',
  'components.guides.subscribe.subtitle': 'Get early access to content like this and much more',
  'components.guides.subscribe.submitBtn': 'Sign Up',
  'components.wishlist.title': 'My Wishlist',
  'components.wishlist.noItems.title': 'Save your favorite artworks here',
  'components.wishlist.noItems.descriptionLine1':
    'Just click {wishlistIcon} underneath the artwork to add it to your wishlist.',
  'components.wishlist.noItems.descriptionLine2':
    'Try {visuallySimilarIcon} to discover visually similar art.',
  'components.wishlist.noItems.button': 'Browse art now',
  'components.follow.noItems.title': 'Save your artists here',
  'components.follow.noItems.descriptionLine1': 'Use {followIcon} to add artists to your list.',
  'components.follow.noItems.button': 'Browse Artists',
  'components.me.artist.title': 'My Artists',
  'components.me.privacy.title': 'My Privacy Settings',
  'components.me.profile.title': 'My Profile',
  'components.me.profile.updated': 'Your profile was successfully updated.',
  'components.me.account.title': 'My Account',
  'components.me.account.unlink': 'Unlink from {network}',
  'components.me.account.deactivate.title': 'Deactivate my account',
  'components.me.account.deactivate.description1':
    'Deactivating your account will disable your profile and remove your personal information from Rise Art. Some information may still be visible to others, such as Events you created.',
  'components.me.account.deactivate.description2':
    'You can <strong>re-activate</strong> your account simply by coming back to Rise Art and signing into your account. We hope to see you back soon.',
  'components.me.account.deactivate.button': 'Deactivate my account',
  'components.me.account.deactivate.confirmation':
    'Are you sure you want to deactivate your account?',
  'components.me.account.facebook.label': 'Facebook',
  'components.me.account.facebook.descriptionUnlinked':
    'Link your Facebook account and use it to sign in to Rise Art.',
  'components.me.account.facebook.descriptionLinked':
    'You can use your Facebook account to sign-in to Rise Art.',
  'components.me.account.google.label': 'Google',
  'components.me.account.google.descriptionUnlinked':
    'Link your Google account and use it to sign in to Rise Art.',
  'components.me.account.google.descriptionLinked':
    'You can use your Google account to sign-in to Rise Art.',
  'components.me.account.notLinked': 'Not linked',
  'components.me.account.linked': 'Linked',
  'components.me.account.emailLabel': 'Email',
  'components.me.account.setPassword': 'Set Password',
  'components.me.account.changeEmail': 'Change Email',
  'components.me.account.changePassword': 'Change Password',
  'components.me.account.unlinkTitle': 'Unlink your {network} account',
  'components.me.account.unlinkConfirmationBody': 'Are you sure you want to unlink your account?',
  'components.me.account.createAccountDescription':
    'Before unlinking your account, you need to create a password. Please set a password that you can use to login with your email address.',
  'components.me.offer.title': 'Artwork {sku}',
  'components.me.offer.listTitle': 'My Offers',
  'components.me.offer.offerTitle': 'Your offer for "{title}"',
  'components.me.offer.created': 'Created on {date}',
  'components.me.offer.expires': 'Expires on {date}',
  'components.me.offer.viewDetails': 'Details',
  'components.me.offer.percentDiscount': '{discount} discount',
  'components.me.offer.status.pending': 'Pending',
  'components.me.offer.status.active': 'Accepted',
  'components.me.offer.status.inactive': 'Inactive',
  'components.me.offer.state.declined': 'Declined',
  'components.me.offer.state.expired': 'Expired',
  'components.me.offer.state.unanswered': 'Unanswered',
  'components.me.offer.state.used': 'Used',
  'components.me.offer.state.canceled': 'Canceled',
  'components.me.offer.state.error': 'Inactive',
  'components.me.offer.accept.action': 'Accept',
  'components.me.offer.accept.title': 'Confirm Accept Offer',
  'components.me.offer.accept.description': 'Are you sure you want to accept the offer?',
  'components.me.offer.cancel.action': 'Cancel',
  'components.me.offer.cancel.title': 'Confirm Cancel Offer',
  'components.me.offer.cancel.description': 'Are you sure you want to cancel the offer?',
  'components.me.offer.noItems.title': 'You have no offers at the moment',
  'components.me.offer.noItems.description':
    'You can browse for art and place offers from the artwork detail page.',
  'components.me.offer.noItems.button': 'Browse art now',
  'components.search.artistTitleBasic': 'Artists',
  'components.search.artistTitle': 'Artists ({count})',
  'components.search.artworkTitleBasic': 'Artworks',
  'components.search.artworkTitle': 'Artworks ({count})',
  'components.search.searchByDomainButton': 'Show all',
  'components.search.noSearchQuery':
    'Please enter a search query above to diplsay your search results.',
  'components.search.noArtists': 'No artists found for your query. Please refine your query.',
  'components.search.noArtworks': 'No artworks found for your query. Please refine your query.',
  'components.fileDropzone.button': 'Browse Files',
  'components.fileDropzone.uploaded': 'The file was uploaded successfully',
  'components.fileDropzone.dialogTitle': 'Upload file',
  'forms.common.close': 'Close',
  'forms.common.save': 'Save',
  'forms.common.saveChanges': 'Save Changes',
  'forms.common.cancel': 'Cancel',
  'forms.common.reset': 'Reset',
  'forms.common.saveSettings': 'Save settings',
  'forms.common.loading': 'Loading...',
  'forms.common.wait': 'Wait...',
  'forms.common.confirmLegal':
    'By clicking ’{actionLabel}’ you confirm you have read, understood and agree to the Rise Art {terms} and {policy}.',
  'forms.common.terms_and_conditions': 'Terms and Conditions',
  'forms.common.privacy_policy': 'Privacy Policy',
  'forms.common.returnPolicies': 'Returns Policies',
  'forms.common.confirmRental':
    'I have read and agree to the terms of the {rental} and authorize Rise Art to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of such agreement.',
  'forms.common.rentalAgreement': 'Rental Agreement',
  'forms.common.confirmSubscribeTitle': 'Are you in for receiving (thrilling) email updates?',
  'forms.common.confirmSubscribeText':
    'We will tempt you with special offers, new pieces, our favourite artists, and trending news.',
  'forms.common.confirmSubscribeYes': 'Yes please, send them my way.',
  'forms.common.confirmSubscribeNo': 'No, I don’t want to hear about the latest offers and news.',
  'forms.common.largerFilesize': 'File size is larger than {maxFilesize} bytes.',
  'forms.common.validation_messages.not_valid_email':
    'This is not a valid Email, please correct it',
  'forms.common.validation_messages.not_empty_email':
    'Email is a required field, please make sure it is not empty',
  'forms.common.validation_messages.not_valid_password':
    'Invalid password. The password should be at least 6 characters long and contain no spaces',
  'forms.common.validation_messages.not_empty_password': 'Password is required, please provide one',
  'forms.common.validation_messages.not_valid_first_name':
    "First name should contain only letters, spaces or '-' and cannot contain numbers",
  'forms.common.validation_messages.not_empty_first_name':
    'First name is required, please provide one',
  'forms.common.validation_messages.not_valid_last_name':
    "Last name should contain only letters, spaces or '-' and cannot contain numbers",
  'forms.common.validation_messages.not_empty_last_name':
    'Last name is required, please provide one',
  'forms.common.validation_messages.notValidName':
    "Name should contain only letters, spaces or '-' and cannot contain numbers",
  'forms.common.validation_messages.not_empty_subscribe':
    'Please make sure you select one of the options',
  'forms.common.validation_messages.invalidPhone': 'This is not a valid phone, please correct it',
  'forms.common.validation_messages.notValidOffer':
    'Offer amount is too low or is not a valid number',
  'forms.common.validation_messages.notEmptySearch':
    'Search is a required field, please make sure it is not empty',
  'forms.common.validation_messages.notEmptyDiscount':
    'Discount code is required, please make sure it is not empty',
  'forms.common.validation_messages.notEmptyPostcode':
    'Postcode/Zipcode is required, please make sure it is not empty',
  'forms.common.validation_messages.notEmptyCity':
    'City is required, please make sure it is not empty',
  'forms.common.validation_messages.notEmptyAddress':
    'Address is required, please make sure it is not empty',
  'forms.common.validation_messages.notEmptyCountry':
    'Country is required, please make sure it is not empty',
  'forms.common.validation_messages.notValidBudget':
    'This is not a valid budget, please correct it',
  'forms.common.validation_messages.required': 'This field is required',
  'forms.common.validation_messages.requiredByCountry':
    'This field is required for the selected country',
  'forms.common.validation_messages.maxPhoneLength': 'Phone number is more than 15 characters long',
  'forms.common.label.email': 'Email',
  'forms.common.label.firstName': 'First Name',
  'forms.common.label.lastName': 'Last Name',
  'forms.common.label.birthDate': 'Date Of birth',
  'forms.common.label.gender': 'Gender',
  'forms.common.label.country': 'Country',
  'forms.common.label.city': 'City',
  'forms.common.label.phone': 'Phone',
  'forms.checkout.firstName': 'First name',
  'forms.checkout.lastName': 'Last name',
  'forms.checkout.email': 'Email',
  'forms.checkout.phone': 'Phone',
  'forms.checkout.company': 'Company',
  'forms.checkout.addressLine1': 'Address Line 1',
  'forms.checkout.addressLine2': 'Address Line 2',
  'forms.checkout.city': 'City',
  'forms.checkout.county': 'State, County or Region',
  'forms.checkout.postcode': 'Postcode/Zipcode',
  'forms.checkout.country': 'Country',
  'forms.checkout.deliveryInstructions': 'Delivery instructions',
  'forms.checkout.cardType': 'Card type',
  'forms.checkout.cardOwner': 'Name on card',
  'forms.checkout.cardNumber': 'Card number',
  'forms.checkout.expirityDate': 'Valid thru',
  'forms.checkout.expirityDatePlaceholder': 'MM / YY',
  'forms.checkout.cvc': 'CVC',
  'forms.checkout.startDate': 'Start date',
  'forms.checkout.issueNumber': 'Issue #',
  'forms.checkout.savedCards': 'Use saved card',
  'forms.checkout.validation.notEmptyCardNumber': 'Card number is required',
  'forms.checkout.validation.notSupportedCardNumber': 'Not supported card type',
  'forms.checkout.validation.notCompleteCardNumber': 'Not complete card number',
  'forms.checkout.validation.notValidCardNumber': 'Not valid card number',
  'forms.checkout.validation.notEmptyCardOwner': 'Card name is required',
  'forms.checkout.validation.notEmptyCardValidThru': 'Card valid thru is required',
  'forms.checkout.validation.notValidCardValidThru': 'Card valid thru must be a date in the future',
  'forms.checkout.validation.cardStartDateNotInPast': 'Card start date must be in the past',
  'forms.checkout.validation.notEmptyCardSecurity': 'Card security code is required',
  'forms.checkout.validation.notValidCardSecurity': 'Not valid CVC',
  'forms.regionalForm.localeSelectLabel': 'Language',
  'forms.regionalForm.currencySelectLabel': 'Show prices in',
  'forms.regionalForm.countrySelectLabel': 'Shipping to',
  'forms.regionalForm.unitsSelectLabel': 'Display units',
  'forms.regionalForm.units.metric': 'Metric (cm, kg)',
  'forms.regionalForm.units.imperial': 'Imperial (in, lb)',
  'forms.subscribeForm.messages.error':
    'We had a problem subscribing you to our mailing list, please try later or contact us',
  'forms.subscribeForm.messages.warning':
    'The email address you provided is already subscribed to our mailing list.',
  'forms.subscribeForm.messages.success':
    "Thanks for subscribing to our mailing list, and welcome to the Rise Art community. Please check your inbox: we've sent you an email to confirm your subscription. We've also included some additional information that you might find useful. If you don't receive the email in the next few minutes please check your spam and junk folders.",
  'forms.subscribeForm.messages.successTitle': 'Success',
  'forms.subscribeForm.placeholder': 'Email Address',
  'forms.subscribeForm.submitButtonText': 'Join',
  'forms.subscribeForm.subscribeTerms':
    'By giving us your email address you agree to receive (thrilling) email updates, including special offers, new pieces and arty news. If you want you can unsubscribe at any time.',
  'forms.searchForm.placeholder': 'Search',
  'forms.searchForm.submit': 'Search the site',
  'forms.searchForm.suggestionTitle': 'Search Suggestions',
  'forms.searchForm.clear': 'Clear the search',
  'forms.auth_form.placeholder.firstName': 'First Name',
  'forms.auth_form.placeholder.lastName': 'Last Name',
  'forms.auth_form.placeholder.email': 'Email Address',
  'forms.auth_form.placeholder.password': 'Password',
  'forms.auth_form.placeholder.newPassword': 'New Password',
  'forms.auth_form.placeholder.show': 'Show Password',
  'forms.auth_form.register_button_text': 'Sign up',
  'forms.auth_form.login_button_text': 'Sign in',
  'forms.auth_form.register_text': 'Already a member?',
  'forms.auth_form.forget_password_text': 'Forgot your password?',
  'forms.auth_form.register_error':
    'We had a problem registering you in our website, please try later or contact us.',
  'forms.auth_form.login_error':
    'We had a problem signing you in our website, please try later or contact us.',
  'forms.auth_form.show_password_text': 'SHOW',
  'forms.auth_form.hide_password_text': 'HIDE',
  'forms.auth_form.sign_up_text': 'Or create a Rise Art account with your email',
  'forms.auth_form.sign_in_text': 'Or, sign in with your Rise Art account',
  'forms.auth_form.forgotPassword.title': 'Forgotten password',
  'forms.auth_form.forgotPassword.subtitle':
    'Enter the email address you registered with Rise Art and we’ll send you instructions on how to reset your password via email.',
  'forms.auth_form.forgotPassword.button': 'Get password',
  'forms.auth_form.forgotPassword.success':
    'We’ve sent you an email to the email address provided. Please check your email account and follow the instructions to reset your password',
  'forms.auth_form.forgotPassword.successButton': 'Go to home page',
  'forms.auth_form.resetPassword.title': 'Change Password',
  'forms.auth_form.resetPassword.subtitle':
    'Enter your email address and a new password below to change your password.',
  'forms.auth_form.resetPassword.button': 'Change Password',
  'forms.single_field_form.submit_text': 'Join',
  'forms.single_field_form.label_text': 'Be in the know',
  'forms.single_field_form.label_form_field_placeholder': 'Email Address',
  'forms.cmsEnquire.successTitle': 'Thank you',
  'forms.cmsEnquire.success': 'Thank you for submitting your request.',
  'forms.emailEnquire.overlayTitle': 'Speak With an Advisor',
  'forms.emailEnquire.send': 'Send',
  'forms.emailEnquire.download': 'Download now',
  'forms.emailEnquire.placeholder.firstName': 'First Name',
  'forms.emailEnquire.placeholder.lastName': 'Last Name',
  'forms.emailEnquire.placeholder.name': 'Name',
  'forms.emailEnquire.placeholder.email': 'Email',
  'forms.emailEnquire.placeholder.phone': 'Phone Number',
  'forms.emailEnquire.placeholder.subject': 'Subject',
  'forms.emailEnquire.placeholder.message': 'Message',
  'forms.emailEnquire.placeholder.company': 'Company',
  'forms.emailEnquire.placeholder.offer': 'Your Offer',
  'forms.emailEnquire.placeholder.budget': 'Budget',
  'forms.emailEnquire.placeholder.moreDetails': 'More Details',
  'forms.emailEnquire.placeholder.country': 'Country',
  'forms.emailEnquire.placeholder.space': 'Space',
  'forms.emailEnquire.placeholder.productPrice': 'List Price',
  'forms.emailEnquire.subject.arrangeViewing': 'Arrange a viewing',
  'forms.emailEnquire.subject.additionalImages': 'Additional artwork images',
  'forms.emailEnquire.subject.pricing': 'Pricing and availability',
  'forms.emailEnquire.subject.shipping': 'Shipping',
  'forms.emailEnquire.subject.sellArtwork': 'I want to sell this artist work',
  'forms.emailEnquire.subject.other': 'Other',
  'forms.emailEnquire.space.livingRoom': 'Living Room',
  'forms.emailEnquire.space.bedroom': 'Bedroom',
  'forms.emailEnquire.space.kidsRoom': "Kid's Room",
  'forms.emailEnquire.space.kitchen': 'Kitchen',
  'forms.emailEnquire.space.other': 'Other',
  'forms.emailEnquire.description': 'Fill in the form to have one of our Advisors contact you.',
  'forms.emailEnquire.advisorsContactDescription':
    'Alternatively, you can call us on <strong>{advisorPhone}</strong> or chat with us directly using the chat icon in the bottom right hand corner of every page.',
  'forms.emailEnquire.success':
    'Thank you for submitting your request. One of our advisors will be in contact with you shortly. ',
  'forms.emailEnquire.successOffer':
    'Thank you for submitting your offer. We have notified the artist and will be in contact with you shortly.',
  'forms.emailEnquire.advisorInfo':
    '<strong>{advisorName}</strong>, our Art Adivsor on call will come back to you as soon as possible.',
  'forms.enquireOffer.title': 'Make an Offer',
  'forms.enquireOffer.description':
    'Fill in the form to make an offer for <strong>{artworkName}</strong> by <strong>{artistName}</strong>.',
  'forms.enquireOffer.offer': 'Offer',
  'forms.enquireOffer.submitBtn': 'Make Offer',
  'forms.enquireArtistCommission.title': 'Commission An Artwork',
  'forms.enquireArtistCommission.description':
    'Fill in the form to make a commision request for <strong>{artistName}</strong>.',
  'forms.enquireArtistCommission.submitBtn': 'Send Request',
  'forms.enquireArtistCommission.extra.moreDetails':
    'Do you have any initial ideas on matters such as size, medium and colours, or reference artworks? Let us know here.',
  'forms.cart.discountCodePlaceholder': 'Enter your discount code',
  'forms.cart.voucherCode': 'Voucher code',
  'forms.cart.voucherCodeHint': 'Enter the 16-digit code (hyphens included)',
  'forms.cart.securityCode': 'Security code',
  'forms.cart.securityCodeHint':
    'If your voucher includes a security code, please provide it to redeem your credit.',
  'forms.cart.redeemGiftCard': 'Redeem gift card',
  'forms.profile.privacy.save': 'Save changes',
  'forms.profile.privacy.fields.newsletter': 'send me the RA newsletter',
  'forms.profile.privacy.fields.activity_update': 'send me activity updates',
  'forms.profile.privacy.fields.activity_weekly': 'send me the weekly activity digest',
  'forms.profile.privacy.fields.product_update': 'send me artwork updates',
  'forms.profile.privacy.fields.general': 'send me general emails',
  'forms.profile.privacy.hints.newsletter':
    'Receive the Rise Art weekly newsletter and keep up to date with the latest news and events',
  'forms.profile.privacy.hints.activity_update':
    'Receive notifications of new activity in your profile (i.e. new followers, new users favouriting your artwork, etc)',
  'forms.profile.privacy.hints.activity_weekly':
    'Receive a weekly email digest summarizing the latest activity on your profile',
  'forms.profile.privacy.hints.product_update':
    'Receive updates relating to artworks from artists I am interested in',
  'forms.profile.privacy.hints.general':
    'Receive general notifications (i.e. recommendations, reminders, etc)',
  'forms.profile.account.newEmail': 'New email',
  'forms.search.domain.all': 'All',
  'forms.search.domain.art': 'Art',
  'forms.search.domain.artist': 'Artists',
  'forms.search.domain.collection': 'Collections',
  'forms.search.yourSearchResults': 'Your search results for:',
  'forms.search.yourArtworkSearchResults': 'Your ARTWORK SEARCH results for:',
  'forms.search.yourArtistSearchResults': 'Your ARTIST SEARCH results for:',
  'forms.search.visual.refresh': 'Refresh',
  'forms.search.visual.restart': 'Restart',
  'forms.search.visual.yourSearchResults': 'Your visually similar results for:',
  'forms.search.visual.help':
    'Click on the visually similar icon on the artworks you like to refine your search',
  'forms.search.visual.searchHint': 'Not sure what to search for? Try <a>Visual Search</a>',
  'forms.search.visual.searchHintShort': 'Not sure what to search? Try <a>Visual Search</a>',
  'forms.search.visual.searchHintButton': '<icon></icon> Try Visual Search',
  'forms.search.visual.searchLensButton': '<icon></icon> Try Lens Search',
  'forms.search.visual.actions.backToResult': 'Back to this result',
  'forms.search.visual.actions.seeArtwork': 'See artwork details',
  'forms.search.visual.actions.addToBasket': 'Add to basket',
  'forms.search.lens.yourSearchResults':
    'Search by uploading an image or select one from already uploaded images:',
  'forms.search.lens.help': 'Drag and drop an image here, or',
  'forms.search.lens.helpCamera':
    'Click here to upload a file or take a picture to search for similar artworks.',
  'forms.search.lens.openLens': 'Open Lens Search',
  'forms.search.lens.authMessage':
    'Please <signin>sign in</signin> or <signup>sign up</signup> first before using Rise Art Lens.',
  'forms.search.lens.overlay.title': 'Rise Art Lens',
  'forms.search.lens.overlay.text': 'Take a photo or upload an image and explore similar artworks.',
  'forms.search.lens.overlay.takePhoto': 'Take a photo',
  'forms.search.lens.overlay.processing': 'Processing image...',
  'forms.search.lens.overlay.cameraHint':
    'You will be asked for permissions to use your device camera, please accept the request.',
  'forms.search.lens.overlay.galleryText': 'Search using a previously uploaded image',
  'forms.search.lens.overlay.linkTitle': 'Search by image',
  'forms.search.lens.overlay.noImages': 'There are no uploaded images',
  'forms.search.lens.overlay.error.permissions':
    'You have not granted Rise Art the required permissions to use your device camera. Please review your browser permissions.',
  'forms.search.lens.overlay.error.device':
    "If you want to use Rise Art Lens 'Take a photo' feature, it is required your mobile device to have a camera.",
  'layouts.logo.title': 'Rise Art',
  'layouts.header.search.placeholder': 'Artist, category, topic...',
  'layouts.header.regionalOverlayTitle': 'Regional Settings',
  'layouts.header.topmenu.sellerConsole': 'Seller Console',
  'layouts.header.topmenu.myPrivacy': 'My Privacy',
  'layouts.header.topmenu.myOffers': 'My Offers',
  'layouts.header.topmenu.login': 'Login',
  'layouts.header.topmenu.cart': 'Cart',
  'layouts.header.topmenu.signIn': 'Sign in',
  'layouts.header.topmenu.signUp': 'Sign up',
  'layouts.header.topmenu.signOut': 'Sign out',
  'layouts.header.topmenu.artistSignUp': 'artist sign up',
  'layouts.header.topmenu.myRiseart': 'my rise art',
  'layouts.header.topmenu.mySettings': 'my settings',
  'layouts.header.topmenu.inviteFriends': 'invite friends',
  'layouts.header.topmenu.myCredit': 'my credit',
  'layouts.header.topmenu.giftCard': 'Gift Card',
  'layouts.header.topmenu.basket': 'Basket',
  'layouts.header.topmenu.wishlist': 'Wishlist',
  'layouts.header.topmenu.myWishlist': 'My Wishlist',
  'layouts.header.topmenu.myArtists': 'My Artists',
  'layouts.header.topmenu.profile': 'My Profile',
  'layouts.header.topmenu.account': 'My Account',
  'layouts.header.topmenu.avatarLabel': 'Profile',
  'layouts.header.topmenu.recentArt': 'My Recent Art',
  'layouts.footer.title': 'Be in the know',
  'layouts.footer.subscribe.title': 'Subscribe to our mailing list.',
  'layouts.footer.subscribe.sub_title':
    'Be the first to receive our latest news, updates and offers!',
  'layouts.footer.zeroFinance': '0% FINANCE',
  'layouts.footer.social.title': 'CONNECT WITH',
  'layouts.footer.social.facebook': 'Like us on Facebook',
  'layouts.footer.social.twitter': 'Follow us on Twitter',
  'layouts.footer.social.pinterest': 'Follow us on Pinterest',
  'layouts.footer.social.instagram': 'Follow us on Instagram',
  'layouts.footer.bottom.copyright': 'Copyright',
  'layouts.footer.bottom.riseart': 'Rise Art Ltd.',
  'layouts.footer.bottom.company': 'Registered UK company 07099261',
  'layouts.footer.bottom.address': '41 Great Portland Street, London W1W 7LA, UK',
  'messages.notifications.titles.error': 'Error',
  'messages.notifications.titles.warning': 'Warning',
  'messages.notifications.titles.info': 'Attention',
  'messages.notifications.titles.success': 'Success',
  'messages.application.cookie.message.short':
    'Cookies help us deliver our services. By using Rise Art you agree to the use of cookies.',
  'messages.application.cookie.message.long':
    'Rise Art uses cookies to deliver and enhance the quality of its services and to analyze traffic. If you agree, cookies are also used to serve advertising and to personalize the content and advertisements that you see.',
  'messages.application.cookie.reject': 'No thanks',
  'messages.application.cookie.accept': 'Agree',
  'messages.application.browser_support':
    'You are using a browser which is not compatible with this website, and some features might not work.',
  'messages.application.artfetch':
    'Artfetch has been acquired by Rise Art, contemporary art hand-picked by experts.',
  'messages.application.kazoart':
    'KAZoART is now Rise Art, buy contemporary art hand-picked by experts. <a>Learn more</a>',
  'messages.application.kinaesthesia':
    'NOW LIVE: Visit our online exhibition<span class="ra-show-for-medium"> - <a href="/exhibition/kinaesthesia" title="KINAESTHESIA: Art In Motion" class="ra-link ra-link-default">KINAESTHESIA: Art In Motion</a></span><div class="ra-show-for-small-only"><a href="/exhibition/kinaesthesia" title="KINAESTHESIA: Art In Motion" class="ra-link ra-link-default">KINAESTHESIA: Art In Motion</a></div>',
  'messages.application.error404':
    "We're sorry, the page you have requested has been moved or deleted.",
  'messages.application.freeShipping.line1': 'FREE SHIPPING',
  'messages.application.freeShipping.line2':
    'when you spend {currencySign}250+ | Ends 13/08, midnight GMT. Offer applied at checkout.',
  'messages.application.freeShipping.line2Truncated':
    'on orders of {currencySign}250+<div>ends 13/08, midnight GMT</div>',
  'messages.application.christmasDelivery.line1':
    '<a href="/christmas" title="GIVE THE GIFT OF ART" class="ra-link ra-link-strong">GIVE THE GIFT OF ART</a>',
  'messages.application.christmasDelivery.line2':
    '<strong>Want guaranteed Christmas delivery?</strong> Order by 17/12 for unframed art and 11/12 for framed art.',
  'messages.application.christmasDelivery.line2Truncated':
    '<strong>Want guaranteed Christmas delivery?</strong> Order by 17/12 for unframed art and 11/12 for framed art.',
  'messages.application.christmasGift.line1': 'Give the Gift of Art with a ',
  'messages.application.christmasGift.line2':
    '<a href="/gift" title="Rise Art Gift Card" class="ra-link ra-link-default">RISE ART GIFT CARD</a>',
  'options.layout.sq': 'Square',
  'options.layout.fl': 'Fluid',
  'options.sort.art.ontherise': 'Trending',
  'options.sort.art.alltimetop': 'Most Popular',
  'options.sort.art.latest': 'Latest Releases',
  'options.sort.art.price': 'Price',
  'options.sort.art.artist': 'All Art',
  'options.sort.art.default': 'All Art',
  'options.sort.art.relevance': 'Relevance',
  'options.sort.artist.featured': 'Featured Artists',
  'options.sort.artist.latest': 'Newest Artists',
  'options.sort.artist.popularity': 'Popular Artists',
  'options.sort.artist.alpha': 'A - Z',
  'options.sort.artist.relevance': 'Relevance',
  'errors.default.title': 'Error',
  'errors.default.details':
    'There was an error in the application. Please reload the page and contact us if you still see this error.',
  'errors.pageNotFound.title': 'Page Not Found',
  'errors.pageNotFound.details': 'The requested URL was not found on this server.',
  'errors.pageNotFound.htmlDetails':
    'The requested URL {notFoundUrl} was not found on this server.',
  'errors.forbiddenException.title': 'Forbidden',
  'errors.forbiddenException.details':
    'You do not have the required access privileges to access this resource.',
  'errors.networkError.title': 'Network Error',
  'errors.networkError.details': 'The connection has limited or no connectivity.',
  'errors.timeoutError.title': 'Timeout Error',
  'errors.timeoutError.details':
    "It's taking too long to load some resources from our servers. Please check your connection and try again.",
  'errors.invalidToken.title': 'Invalid Authorization Error',
  'errors.invalidToken.details':
    'Your session has expired. Please refresh the page to renew the session.',
  'errors.apiServerError.title': 'Server Error',
  'errors.apiServerError.details':
    'We are working hard on fixing this issue. Thank you for your patience.',
  'errors.notFoundVisitor.title': 'No identity found',
  'errors.notFoundVisitor.details':
    'There were some issue loading data, while initializing the application. Some features might not work correctly. Please refresh your application!',
  'meta.home.pageTitle': 'Contemporary art for sale. Buy fine art online. Online art gallery.',
  'meta.home.title': 'Buy Fine Art Online | Contemporary Art For Sale | Rise Art',
  'meta.home.keywords':
    'fine art, fine art for sale, buy fine art online, buy art, buy art online, contemporary art, contemporary art for sale, original art, original art for sale, buy original art, online art, online art gallery, online art marketplace, art advisors, art advisory service, curated art, art curation',
  'meta.home.description':
    'Collect fine art from exciting artists from around the world. Explore our online art gallery with contemporary art for sale that has been expertly vetted by our curators. Buy fine art online with confidence with free art advisory and returns.',
  'meta.art.pageTitle': 'Contemporary art for sale. Buy art online. Online art gallery.',
  'meta.art.title': 'Browse Art | Buy Contemporary Art Online | Rise Art',
  'meta.art.keywords':
    'contemporary art, contemporary arts, contemporary abstract art, art dealers, art for sale, art websites, buy art, buy art online, original art, original art for sale, buy original art, art original, canvas wall art, fine art, fine art for sale, buy fine art online, framed art, modern art, modern art for sale, buy modern art online, online art, online art gallery, art advisors, art advisory service, art dealer, online art dealer, curated art, art curation, interior design help, art for the office, office art',
  'meta.art.description':
    'Browse contemporary art from exciting artists from around the world. Explore our online art gallery with artwork for sale including paintings, prints, sculpture, photography and drawing in a range of styles from abstract expressionism to pop-art. Buy or rent art online with confidence.',
  'meta.artDetails.pageTitle': 'Contemporary art for sale. Buy art online. Online art gallery.',
  'meta.artDetails.title': 'Buy Contemporary Art Online | Rise Art',
  'meta.artDetails.keywords':
    'buy art online, original art, art, online art gallery, artists, contemporary art, art advisory, art for sale',
  'meta.artDetails.description':
    'Discover, own and collect original art from exciting artists from around the world. We’re an online art gallery, that means each artwork for sale has been expertly vetted by our curators. Buy art online with confidence with free art advisory and 14 days free returns.',
  'meta.artDetails.dynamicMetaTitle': '{name} by {artist} | Buy Art Online | Rise Art',
  'meta.artDetails.dynamicPageTitle': 'Buy {name} by {artist}. Online art gallery.',
  'meta.artDetails.dynamicMetaDescription':
    "Find {name} by {artist} online. Choose from thousands of contemporary artworks from exciting artists expertly-vetted by Rise Art's curators. Buy art online with confidence with free art advisory.",
  'meta.artist.pageTitle': 'Contemporary artists for sale. Buy art online. Online art gallery.',
  'meta.artist.title': 'Browse Artists | Buy Contemporary Art Online | Rise Art',
  'meta.artist.keywords':
    'contemporary art, contemporary arts, contemporary abstract art, art dealers, art for sale, art websites, buy art, buy art online, original art, original art for sale, buy original art, art original, canvas wall art, fine art, fine art for sale, buy fine art online, framed art, modern art, modern art for sale, buy modern art online, online art, online art gallery, art advisors, art advisory service, art dealer, online art dealer, curated art, art curation, interior design help, art for the office, office art',
  'meta.artist.description':
    'Browse contemporary art from exciting artists from around the world. Explore our online art gallery with artwork for sale including paintings, prints, sculpture, photography and drawing in a range of styles from abstract expressionism to pop-art. Buy or rent art online with confidence.',
  'meta.artistProfile.dynamicMetaTitle': '{name}, Artist | Contemporary Art for Sale | Rise Art',
  'meta.artistProfile.dynamicMetaDescription':
    'View our selection of artist {name} art for sale. Buy contemporary art online from an expertly curated group of talented artists with Rise Art.',
  'meta.artistProfile.dynamicMetaKeywords':
    '{name}, artists, famous artists, celebrity artists, buy art online, affordable art, original art, art, unique gifts, limited edition prints, rent art, artists, contemporary art, museums',
  'meta.artistArt.dynamicMetaTitle': 'Artworks by {name} | Contemporary Art for Sale | Rise Art',
  'meta.artistArt.dynamicMetaDescription':
    'Browse our selection of artworks for sale by artist {name}. Buy contemporary art online from {name} with Rise Art.',
  'meta.artistArt.dynamicMetaKeywords':
    '{name}, artists, famous artists, celebrity artists, buy art online, affordable art, original art, art, unique gifts, limited edition prints, rent art, artists, contemporary art, museums',
  'meta.artistCv.dynamicMetaTitle': 'CV for {name} | Rise Art',
  'meta.artistStudio.dynamicMetaTitle': 'In the Studio with {name} | Rise Art',
  'meta.artistNews.dynamicMetaTitle': 'News & Exhibitions for {name} | Rise Art',
  'meta.activity.pageTitle': 'Latest Activity | Rise Art',
  'meta.activity.title': 'Latest Activity | Rise Art',
  'meta.activity.keywords':
    'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
  'meta.activity.description':
    'Rise Art is an online social community where anyone can discover, share and purchase unique artwork from rising talent around the globe. Rise Art enables users to directly connect and engage with the artists and their work online, and presents an exclusive gallery of curated works for sale.',
  'meta.guides.pageTitle':
    'Art Guides | Art Movements, Art Styling, Featured Artists, How To | Rise Art',
  'meta.guides.title':
    'Art Guides | Art Movements, Art Styling, Featured Artists, How To | Rise Art',
  'meta.guides.keywords':
    'art guides, how to, art styling, featured artists, contemporary art, art movements, art history',
  'meta.guides.description':
    'Find all you need to know on art styling, featured artists, key art movements and the ultimate ‘how to’ guides. Our art guides cover a range of topics and themes and offer helpful information on everything from arranging art, to learning more about the history of art movements.',
  'meta.guidesCategory.pageTitle':
    'Art Guides | Art Movements, Art Styling, Featured Artists, How To | Rise Art',
  'meta.guidesCategory.title':
    'Art Guides | Art Movements, Art Styling, Featured Artists, How To | Rise Art',
  'meta.guidesCategory.keywords':
    'art guides, how to, art styling, featured artists, contemporary art, art movements, art history',
  'meta.guidesCategory.description':
    'Find all you need to know on art styling, featured artists, key art movements and the ultimate ‘how to’ guides. Our art guides cover a range of topics and themes and offer helpful information on everything from arranging art, to learning more about the history of art movements.',
  'meta.guidesCategory.dynamicMetaTitle':
    '{title} | Art Movements, Art Styling, Featured Artists, How To | Rise Art',
  'meta.guidesCategory.dynamicMetaKeywords':
    '{title}, art guides, how to, art styling, featured artists, contemporary art, art movements, art history',
  'meta.guidesCategory.dynamicMetaDescription':
    'Find all you need to know about {title} with our art guides, covering a range of topics and themes and offering helpful information on everything from choosing art, to learning more about {title}.',
  'meta.guidesArticle.title':
    'Art Guides | Art Movements, Art Styling, Featured Artists, How To | Rise Art',
  'meta.guidesArticle.keywords':
    'art guides, how to, art styling, featured artists, contemporary art, art movements, art history',
  'meta.guidesArticle.description':
    'Find all you need to know on art styling, featured artists, key art movements and the ultimate ‘how to’ guides. Our art guides cover a range of topics and themes and offer helpful information on everything from arranging art, to learning more about the history of art movements',
  'meta.guidesArticle.dynamicMetaTitle': '{title} | Rise Art',
  'meta.guidesArticle.dynamicMetaKeywords':
    '{title}, {tags}, art guides, how to, art styling, featured artists, contemporary art, art movements, art history',
  'meta.guidesArticle.dynamicMetaDescription':
    'Find all you need to know about {title} with our art guides, covering a range of topics and themes and offering helpful information on everything from choosing art, to learning more about {title}.',
  'meta.blog.pageTitle': 'Art Blog | News, Releases, Art Events | Rise Art',
  'meta.blog.title': 'Art Blog | News, Releases, Art Events | Rise Art',
  'meta.blog.keywords':
    'art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.blog.description':
    'Discover the latest art news, recent releases and events. Whether you’re wanting to keep up-to-date with art world news, looking to gain more of an insight into art history, or interested in learning about the key art movements on the scene, find all you need to know about contemporary art with the Rise Art blog.',
  'meta.blogCategory.pageTitle': 'Art Blog | News, Releases, Art Events | Rise Art',
  'meta.blogCategory.title': 'Art Blog | News, Releases, Art Events | Rise Art',
  'meta.blogCategory.keywords':
    'art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.blogCategory.description':
    'Discover the latest art news, recent releases and events. Whether you’re wanting to keep up-to-date with art world news, looking to gain more of an insight into art history, or interested in learning about the key art movements on the scene, find all you need to know about contemporary art with the Rise Art blog.',
  'meta.blogCategory.dynamicMetaTitle': '{title} | News, Releases, Art Events | Rise Art',
  'meta.blogCategory.dynamicMetaKeywords':
    '{title}, art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.blogCategory.dynamicMetaDescription':
    'Discover the latest {title}, art news and events. Whether you’re wanting to keep up-to-date with art world news, looking to gain more of an insight into art history, or interested in learning about the key art movements on the scene, find all you need to know about {title} with the Rise Art blog.',
  'meta.blogArticle.title': 'Art Blog | News, Releases, Art Events | Rise Art',
  'meta.blogArticle.keywords':
    'art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.blogArticle.description':
    'Discover the latest art news, recent releases and events. Whether you’re wanting to keep up-to-date with art world news, looking to gain more of an insight into art history, or interested in learning about the key art movements on the scene, find all you need to know about contemporary art with the Rise Art blog.',
  'meta.blogArticle.dynamicMetaTitle': '{title} | Rise Art',
  'meta.blogArticle.dynamicMetaKeywords':
    '{title}, {tags}, art articles, art blog, art news, art events, latest news, latest art releases, online art gallery',
  'meta.blogArticle.dynamicMetaDescription': '{summary}',
  'meta.guide.pageTitle': 'Art Blog | News, Releases, Art Events | Rise Art',
  'meta.guide.title': 'Art Blog | News, Releases, Art Events | Rise Art',
  'meta.guide.keywords':
    'art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.guide.description':
    'Discover the latest and greatest art news, releases and events through our Rise Art blog. Our art blog is a great place to learn more about art. We publish great features on loads of topics, from styling with art at home to going behind the scenes with our artists in their studios.',
  'meta.guideCategory.pageTitle': 'Art Blog | News, Releases, Art Events | Rise Art',
  'meta.guideCategory.title': 'Art Blog | News, Releases, Art Events | Rise Art',
  'meta.guideCategory.keywords':
    'art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.guideCategory.description':
    'Discover the latest and greatest art news, releases and events through our Rise Art blog. Our art blog is a great place to learn more about art. We publish great features on loads of topics, from styling with art at home to going behind the scenes with our artists in their studios.',
  'meta.guideCategory.dynamicMetaTitle': '{title} | News, Releases, Art Events | Rise Art',
  'meta.guideCategory.dynamicMetaKeywords':
    '{title}, art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.guideCategory.dynamicMetaDescription':
    'Discover the latest {title} art news, releases and events through our Rise Art blog. Our art blog is a great place to learn more about art. We publish great features on loads of topics, from {title} to going behind the scenes with our artists in their studios.',
  'meta.guideArticle.title': 'Art Blog | News, Releases, Art Events | Rise Art',
  'meta.guideArticle.keywords':
    'art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.guideArticle.description':
    'Discover the latest and greatest art news, releases and events through our Rise Art blog. Our art blog is a great place to learn more about art. We publish great features on loads of topics, from styling with art at home to going behind the scenes with our artists in their studios.',
  'meta.guideArticle.dynamicMetaTitle': '{title} | Rise Art',
  'meta.guideArticle.dynamicMetaDescription':
    'Discover the latest {title} art news, releases and events through our Rise Art blog. Our art blog is a great place to learn more about art. We publish great features on loads of topics, from {title} to going behind the scenes with our artists in their studios.',
  'meta.guideArticle.dynamicMetaKeywords':
    '{title}, {tags}, art articles, art blog, art news, art events, latest news, latest art releases, online art gallery',
  'meta.event.pageTitle': 'Discover and RSVP to upcoming Art Gallery exhibitions and events',
  'meta.event.title': 'Art Events | Upcoming Art and Gallery Events | Rise Art',
  'meta.event.keywords':
    'Rent Art, Buy Art, Art, Limited Edition Prints, Posters, Artist, Emerging Artist, Emerging Art, Discover Art, Purchase Art, Buy Art, Original Art, Social, Artist Community, Art Blog, Contemporary Art, Graduate Art, Curated Art, Exclusive Art, Affordable Art, Original Art',
  'meta.event.description':
    'All Art Events. Discover and RSVP to upcoming Gallery exhibitions and events. Use Rise Art to discover original art from talented artists. Rent and buy art from an expertly curated selection of paintings, photography and limited edition prints at affordable prices.',
  'meta.event.dynamicMetaTitle': '{name} | Art Event | Rise Art',
  'meta.event.dynamicMetaDescription':
    'RSVP and receive updates for {name} in {address} on {date}. Use Rise Art to discover upcoming art events and new art from talented artists. Rent and buy art from an expertly curated selection of paintings, photography and limited edition prints at affordable prices.',
  'meta.event.dynamicMetaKeywords':
    'Rent Art, Buy Art, Art, Limited Edition Prints, Posters, Artist, Emerging Artist, Emerging Art, Discover Art, Purchase Art, Buy Art, Original Art, Social, Artist Community, Art Blog, Contemporary Art, Graduate Art, Curated Art, Exclusive Art, Affordable Art, Original Art',
  'meta.eventList.pageTitle': 'Discover and RSVP to upcoming Art Gallery exhibitions and events',
  'meta.eventList.title': 'Art Events | Upcoming Art and Gallery Events | Rise Art',
  'meta.eventList.keywords':
    'Rent Art, Buy Art, Art, Limited Edition Prints, Posters, Artist, Emerging Artist, Emerging Art, Discover Art, Purchase Art, Buy Art, Original Art, Social, Artist Community, Art Blog, Contemporary Art, Graduate Art, Curated Art, Exclusive Art, Affordable Art, Original Art',
  'meta.eventList.description':
    'All Art Events. Discover and RSVP to upcoming Gallery exhibitions and events. Use Rise Art to discover original art from talented artists. Rent and buy art from an expertly curated selection of paintings, photography and limited edition prints at affordable prices.',
  'meta.collectionList.pageTitle':
    'Discover art collections. Buy contemporary art online. Online art gallery.',
  'meta.collectionList.title': 'Curated Art Collections | Buy Art Online | Rise Art',
  'meta.collectionList.keywords':
    'art collections, art themed collections, buy art online, original art, art, online art gallery, artists, contemporary art, art advisory, art for sale',
  'meta.collectionList.description':
    'Discover curated art collections on Rise Art. Explore our online art gallery with a wide selection of art collections expertly curated by our art specialists. Buy art online with confidence with free art advisory.',
  'meta.collectionDetails.pageTitle':
    'Art collection. Buy contemporary art online. Online art gallery.',
  'meta.collectionDetails.title': 'Buy Art Online | Rise Art',
  'meta.collectionDetails.keywords':
    'art collections, art themed collections, buy art online, original art, art, online art gallery, artists, contemporary art, art advisory, art for sale',
  'meta.collectionDetails.description':
    'Discover curated art collections on Rise Art. We’re an online art gallery, that means each artwork for sale has been expertly vetted by our curators. Buy art online with confidence with free art advisory and 14 days free returns.',
  'meta.collectionDetails.dynamicMetaTitle': '{name} | Buy Art Online | Rise Art',
  'meta.collectionDetails.dynamicPageTitle':
    '{name} art collection on Rise Art. Online art gallery.',
  'meta.collectionDetails.dynamicMetaDescription':
    'Discover {name} art collection on Rise Art. Explore our online art gallery with a wide selection of art collections expertly curated by our art specialists. Buy art online with confidence with free art advisory.',
  'meta.myWishlist.pageTitle': 'Buy art online at affordable prices. Online art gallery.',
  'meta.myWishlist.title': 'Your Wishlist | Buy Art Online | Rise Art',
  'meta.myWishlist.keywords':
    'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
  'meta.myWishlist.description':
    'View and edit your artwork wishlist. Discover contemporary art hand-picked by experts. Affordable original art for sale including Paintings, Photography and Limited Edition Prints.',
  'meta.myArtists.pageTitle': 'Buy art online at affordable prices. Online art gallery.',
  'meta.myArtists.title': 'My Artists | Rise Art',
  'meta.myArtists.keywords':
    'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
  'meta.myArtists.description':
    'View and edit your followed artists. Discover contemporary art hand-picked by experts. Affordable original art for sale including Paintings, Photography and Limited Edition Prints.',
  'meta.myOffers.pageTitle': 'Buy art online at affordable prices. Online art gallery.',
  'meta.myOffers.title': 'My Offers | Rise Art',
  'meta.myOffers.keywords':
    'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
  'meta.myOffers.description':
    'View your art offers. Discover contemporary art hand-picked by experts. Affordable original art for sale including Paintings, Photography and Limited Edition Prints.',
  'meta.myOffer.pageTitle': 'Buy art online at affordable prices. Online art gallery.',
  'meta.myOffer.title': 'My Offer | Rise Art',
  'meta.myOffer.keywords':
    'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
  'meta.myOffer.description':
    'View your art offer details. Discover contemporary art hand-picked by experts. Affordable original art for sale including Paintings, Photography and Limited Edition Prints.',
  'meta.quiz.pageTitle':
    'Discover your art personality on Rise Art. Take our free personality test.',
  'meta.quiz.title': 'Art Personality Test | Discover Your Art Style | Rise Art',
  'meta.quiz.keywords':
    'personality test, free personality test, art, art quiz, art personality quiz',
  'meta.quiz.description':
    'What does the art you like say about your personality? Take our free Art Personality Test and find out what your visual preferences say about you.',
  'meta.quiz.ogTitle': 'The Rise Art Personality Test',
  'meta.quiz.ogDescription':
    "Take the test and find out if we can match art to your personality. We've based it on research in behavioural psychology.",
  'meta.quizQuestions.pageTitle':
    'Discover your art personality on Rise Art. Take our free personality test.',
  'meta.quizQuestions.title': 'Art Personality Test | Discover Your Art Style | Rise Art',
  'meta.quizQuestions.keywords':
    'personality test, free personality test, art, art quiz, art personality quiz',
  'meta.quizQuestions.description':
    'What does the art you like say about your personality? Take our free Art Personality Test and find out what your visual preferences say about you.',
  'meta.quizResult.pageTitle': 'Your art personality and art taste',
  'meta.quizResult.title': 'Your Art Personality & Art Taste | Rise Art',
  'meta.quizResult.keywords':
    'affordable art, rent art, buy art, art quiz, art style, art style quiz',
  'meta.quizResult.description':
    'Get personalized recommendations from our art advisors based on your art taste. Buy Original Art online from top museums, galleries and artists. Discover original artwork for sale around your unique taste',
  'meta.quizProfile.pageTitle':
    'Discover your art personality on Rise Art. Take our free personality test.',
  'meta.quizProfile.title': 'Art Personality Test | Rise Art',
  'meta.quizProfile.keywords':
    'personality test, free personality test, art, art quiz, art personality quiz',
  'meta.quizProfile.description':
    'What does the art you like say about your personality? Take our free Art Personality Test and find out what your visual preferences say about you.',
  'meta.quizProfile.dynamicMetaTitle': '{name} | Art Personality Test | Rise Art',
  'meta.quizProfile.dynamicPageTitle':
    '{name}. Art personality test. Take our free personality test.',
  'meta.quizProfile.dynamicMetaDescription':
    "I'm {name}. What does the art you like say about your personality? Take our Art Personality Test and find out what your visual preferences say about you.",
  'meta.quizRegister.pageTitle':
    'Discover you art personality on Rise Art. Take our free art style quiz.',
  'meta.quizRegister.title': 'Discover your Art Style | Art taste Quiz | Rise Art',
  'meta.quizRegister.keywords':
    'affordable art, rent art, buy art, art quiz, art style, art style quiz',
  'meta.quizRegister.description':
    'Get personalized recommendations from our art advisors based on your art taste. Buy Original Art online from top museums, galleries and artists. Discover original artwork for sale around your unique taste',
  'meta.quizLogin.pageTitle':
    'Discover you art personality on Rise Art. Take our free art style quiz.',
  'meta.quizLogin.title': 'Discover your Art Style | Art taste Quiz | Rise Art',
  'meta.quizLogin.keywords':
    'affordable art, rent art, buy art, art quiz, art style, art style quiz',
  'meta.quizLogin.description':
    'Get personalized recommendations from our art advisors based on your art taste. Buy Original Art online from top museums, galleries and artists. Discover original artwork for sale around your unique taste',
  'meta.cart.title': 'Basket | Rise Art',
  'meta.checkout.title': 'Checkout | Rise Art',
  'meta.privacy.pageTitle': 'My Privacy Settings',
  'meta.privacy.title': 'My Privacy Settings | Rise Art',
  'meta.profile.pageTitle': 'My Profile',
  'meta.profile.title': 'My Profile | Rise Art',
  'meta.account.pageTitle': 'My Account',
  'meta.account.title': 'My Account | Rise Art',
  'meta.login.pageTitle': 'Sign In to Rise Art',
  'meta.login.title': 'Sign In | Rise Art',
  'meta.register.pageTitle': 'Sign Up to Rise Art',
  'meta.register.title': 'Sign Up | Rise Art',
  'meta.forgotPassword.pageTitle': 'Forgotten Password',
  'meta.forgotPassword.title': 'Forgotten Password | Rise Art',
  'meta.resetPassword.pageTitle': 'Change Password',
  'meta.resetPassword.title': 'Change Password | Rise Art',
  'meta.signout.pageTitle': 'Sign Out from Rise Art',
  'meta.signout.title': 'Sign out | Rise Art',
  'meta.search.pageTitle': 'Search',
  'meta.search.title': 'Search | Rise Art',
  'meta.error.pageTitle': 'Buy art online at affordable prices. Online art gallery.',
  'meta.error.title': 'Buy Art Online | Affordable Art For Sale | Rise Art',
  'meta.error.keywords':
    'buy art online, affordable art, original art, art, unique gifts, limited edition prints, rent art, artists, contemporary art, museums',
  'meta.error.description':
    'Buy affordable art online from Top Museums, Galleries and Artists. Discover affordable original art for sale including Paintings, Photography and Limited Edition Prints.',
  'meta.common.pageTitle': 'Buy art online at affordable prices. Online art gallery.',
  'meta.common.title': 'Buy Art Online | Affordable Art For Sale | Rise Art',
  'meta.common.keywords':
    'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
  'meta.common.description':
    'Rise Art is an online social community where anyone can discover, share and purchase unique artwork from rising talent around the globe. Rise Art enables users to directly connect and engage with the artists and their work online, and presents an exclusive gallery of curated works for sale.',
};

export default function Locale({ children }) {
  return children(messages);
}
